import { message } from 'antd'
import axios from 'axios'

const logError = (err) => {
	if (err.response) {
		const { status, statusText, data } = err.response
		const { method, url, baseURL } = err.response.config

		let error = ''
		error += `\nError ${status}: ${statusText}`
		error += `\nmethod: ${method}`
		error += `\nbase url: ${baseURL}\nendpoint: ${url}`
		error += `${!data.message ? `\ndata:\n${data}` : ''}`
		// eslint-disable-next-line no-console
		console.error(error)
	} else {
		// eslint-disable-next-line no-console
		console.error(err)
	}
}

const API_URL = process.env.REACT_APP_API_URL

const instance = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json'
	}
})

// request handler
instance.interceptors.request.use(
	(config) => {
		const temp = config
		const access = localStorage.getItem('access_token')
		if (access) {
			temp.headers.Authorization = `Bearer ${access}`
		}
		return temp
	},
	(error) => Promise.reject(error)
)

// response handler
instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (process.env.NODE_ENV === 'development') logError(error)
		const originalRequest = error.config
		const errCode = error?.response?.status || undefined
		const errMessage = error?.response?.data?.message || undefined
		if (errMessage && errCode !== 401) message.error(errMessage)

		if (!error) {
			message.error('Unexpected error')
		} else if (typeof error.response === 'undefined') {
			message.error({ content: 'Network Error', key: 'NETWORK_ERROR' })
		} else if (
			error.code === 'ECONNABORTED' ||
			(error.response && error.response.status === 408)
		) {
			message.error('The server is taking to long to respond')
		} else if (errCode === 401) {
			const terminateSession = () => {
				localStorage.clear()
				message.error('Your session has expired')
				setTimeout(() => {
					window.location.reload()
				}, 2500)
			}

			if (!originalRequest.retry) {
				originalRequest.retry = true
				const refresh = localStorage.getItem('refresh_token')

				if (refresh) {
					return axios
						.post(`${API_URL}/admin_auth/token`, {
							refresh_token: refresh
						})
						.then(({ data }) => {
							localStorage.setItem('access_token', data.access_token || '')
							localStorage.setItem('refresh_token', data.refresh_token || '')

							instance.defaults.headers.common.Authorization = `Bearer ${
								data.access_token || ''
							}`
							return instance(originalRequest)
						})
						.catch(() => {
							terminateSession()
							return Promise.reject(error)
						})
				}
			} else {
				terminateSession()
				return Promise.reject(error)
			}
		} else if (errCode === 429) {
			message.error('Too many request')
		} else if (errCode >= 500 && errCode < 600) {
			message.error('Internal Server Error')
		} else if (errCode === 400) {
			const errData = error.response.data
			if (
				errData.error &&
				Array.isArray(errData.error) &&
				errData.error.length > 0
			) {
				errData.error.map((item) => message.error(item))
				return Promise.reject(error)
			}
			if (typeof errData === 'object' && errData !== null) {
				Object.values(errData).map((item) => message.error(item))
				return Promise.reject(error)
			}
			if (typeof errData === 'string') {
				message.error(errData)
			} else {
				message.error('Error in data handling')
			}

			return Promise.reject(error)
		}

		return Promise.reject(error)
	}
)

export default instance

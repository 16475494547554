import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Switch } from 'antd'
import { object, oneOf } from 'prop-types'

import { uniqueID } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

export default function MCorMRorMCIQuestion({
	formInstance,
	type,
	initialValues
}) {
	// const handleSwitchChange = (field, checked) => {
	// 	formInstance.setFieldsValue({
	// 		choices: formInstance.getFieldValue('choices').map((choice, index) => {
	// 			if (index === field.name) {
	// 				return {
	// 					...choice,
	// 					is_answer: checked
	// 				}
	// 			}
	// 			if (
	// 				index !== field.name &&
	// 				checked &&
	// 				(type === 'MC' || type === 'MCI')
	// 			) {
	// 				return {
	// 					...choice,
	// 					is_answer: false
	// 				}
	// 			}
	// 			return choice
	// 		})
	// 	})
	// }

	return (
		<Form
			name={`${type}_question`}
			form={formInstance}
			initialValues={initialValues}
		>
			<Form.Item
				name="questionText"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={5} />
			</Form.Item>
			<Form.List
				name="choices"
				rules={[
					{
						validator: async (_, choices) => {
							// if (!choices || choices.length < 2) {
							// 	return Promise.reject(new Error('At least 2 choice'))
							// }
							if (!choices.some((el) => el.is_answer === true))
								return Promise.reject(
									new Error(
										`Please select ${
											type === 'MR' ? 'at least' : ''
										} one choice as answer`
									)
								)

							return Promise.resolve()
						}
					}
				]}
			>
				{(fields, { add, remove }, { errors }) => (
					<>
						<div className="flex flex-row items-center justify-between">
							<h3 className="font-normal">Choices:</h3>
							{type === 'MCI' && (
								<h3 className="font-normal ml-auto mr-12">Icon:</h3>
							)}
							<h3 className="font-normal mr-4">Is Answer?:</h3>
						</div>
						{fields.map((field, index) => (
							<Form.Item
								required={false}
								key={field.key}
								// className="border-b-2 last:border-b-0 my-4"
							>
								<div className="w-full flex flex-row flex-nowrap items-center mb-2">
									<Form.Item
										name={[field.name, 'text']}
										fieldKey={[field.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message: 'Please input choice text'
											}
										]}
										noStyle
									>
										<Input
											className="mr-4"
											placeholder={`Choice ${index + 1}`}
										/>
									</Form.Item>
									{type === 'MCI' && (
										<Form.Item
											name={[field.name, 'icon']}
											fieldKey={[field.fieldKey, 'icon']}
											className="m-0 mr-2"
											rules={[
												{
													required: true,
													message: 'Please upload an icon for this choice'
												}
											]}
										>
											<div className="flex flex-col items-start justify-start w-max">
												<SingleFileUploader
													type="other"
													onlyPNG
													defaultFile={
														formInstance.getFieldValue('choices')[field.name]
															?.icon
													}
													getFileAddressInsteadOfID
													afterUpload={(fileAddress) =>
														formInstance.setFieldsValue({
															choices: formInstance
																.getFieldValue('choices')
																.map((choice, i) => {
																	if (i === field.name) {
																		return {
																			...choice,
																			icon: fileAddress
																		}
																	}
																	return choice
																})
														})
													}
												/>
											</div>
										</Form.Item>
									)}
									<Form.Item
										name={[field.name, 'is_answer']}
										fieldKey={[field.fieldKey, 'is_answer']}
										className="m-0"
										valuePropName="checked"
									>
										<Switch
											className="focus:outline-black mr-4"
											// onChange={(checked) => handleSwitchChange(field, checked)}
											// checked={
											// 	formInstance.getFieldValue('choices')[field.name]
											// 		.is_answer
											// }
										/>
									</Form.Item>
									<button
										type="button"
										className="flex items-center focus:text-red-500 focus:outline-black"
									>
										<MinusCircleOutlined
											className="text-inherit"
											onClick={() => remove(field.name)}
										/>
									</button>
								</div>

								{/* {type === 'MCI' && (
									<div className="w-full flex flex-row mb-4">
										<Form.Item
											label="Icon"
											name={[field.name, 'icon']}
											fieldKey={[field.fieldKey, 'icon']}
											className="m-0 mr-2"
											rules={[
												{
													required: true,
													message: 'Please upload an icon for this choice'
												}
											]}
										>
											<div className="flex flex-col items-start justify-start w-max">
												<SingleFileUploader
													type="other"
													onlyPNG
													defaultFile={
														formInstance.getFieldValue('choices')[field.name]
															?.icon
													}
													getFileAddressInsteadOfID
													afterUpload={(fileAddress) =>
														formInstance.setFieldsValue({
															choices: formInstance
																.getFieldValue('choices')
																.map((choice, i) => {
																	if (i === field.name) {
																		return {
																			...choice,
																			icon: fileAddress
																		}
																	}
																	return choice
																})
														})
													}
												/>
											</div>
										</Form.Item>
									</div>
								)} */}
							</Form.Item>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => {
									const newChoice = {
										id: uniqueID(),
										text: ``,
										is_answer: false
									}
									if (type === 'MCI') Object.assign(newChoice, { icon: null })
									add(newChoice)
								}}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								Add choice
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	)
}

MCorMRorMCIQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	type: oneOf(['MC', 'MR', 'MCI']).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}

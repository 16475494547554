import { useCallback, useState } from 'react'
import qs from 'query-string'

import { axios } from '../utils'

export default function useUsers() {
	const [data, setData] = useState({
		users: [],
		totalCount: 0
	})
	const [isLoading, setLoading] = useState(true)

	const fetchUsers = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin_user?${qs.stringify(queryParams, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					// eslint-disable-next-line camelcase
					.then(({ data: { data: users, total_count } }) => {
						setData({ users, totalCount: total_count })
						resolve(users)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchUser = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				if (!id) {
					reject(new Error('user ID not provided'))
					setLoading(false)
				} else {
					axios
						.get(`/admin_user/${id}`)
						.then(({ data: user }) => resolve(user))
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	return {
		data: data.users,
		totalCount: data.totalCount,
		isLoading,
		fetchUsers,
		fetchUser
	}
}

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, message, Modal, Spin } from 'antd'
import { arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types'

import { useQuestions } from '../../hooks'
import DeleteBtn from '../delete-btn'

import MicroLessonDataSelector from './micro-lesson-data-selector'
import QuestionDetailsSelector from './question-details-selector'
import QuizQuestionDataSelector from './quiz-question-data-selector'

export default function QuestionModal({
	isVisible,
	questionCategory,
	onClose,
	onAdd,
	onEdit,
	onDelete,
	queryParams,
	title,
	ctaBtnText,
	subjects,
	subjectsIsLoading,
	questionTypes,
	questionTypesIsLoading
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [questionFixedDataForm] = Form.useForm()
	const [questionDetailForm] = Form.useForm()
	const [selectedType, setSelectedType] = useState(undefined)
	const [record, setRecord] = useState(undefined)
	const { details: _, ...restQuestionData } = record || {}

	const { fetchQuestion } = useQuestions()

	const modalIsVisible = useMemo(() => {
		if (isVisible !== undefined) return isVisible
		const { modal_is_open, mode, record_id } = queryParams || {}
		return (
			modal_is_open &&
			((mode === 'edit_question' && record_id) || mode === 'add')
		)
	}, [isVisible, queryParams])

	const handleClose = useCallback(() => {
		setRecord(undefined)
		onClose()
	}, [onClose])

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setSelectedType(undefined)
		questionFixedDataForm.resetFields()
		questionDetailForm.resetFields()
	}, [questionDetailForm, questionFixedDataForm])

	const handleSubmit = () => {
		questionFixedDataForm.validateFields().then((values) => {
			questionDetailForm.validateFields().then((details) => {
				if (!Object.keys(details).length) {
					message.error('Question details should not be empty')
				} else {
					setIsSubmitting(true)
					const data = { ...details }
					if (selectedType.name === 'SQ') {
						Object.assign(data, {
							choices: details?.choices.map((choice, indx) => ({
								...choice,
								priority: indx + 1
							}))
						})
					}
					if (selectedType.name === 'DW') {
						const newChoices = []
						details?.choices.forEach((choice, indx) => {
							const i = newChoices.findIndex((el) => el.text === choice.text)
							if (i === -1) {
								newChoices.push({
									...choice,
									priority: [indx + 1]
								})
							} else {
								newChoices[i].priority.push(indx + 1)
							}
						})
						Object.assign(data, {
							choices: newChoices
						})
					}
					Object.assign(values, {
						status: values.status ? 'active' : 'inactive'
					})
					if (values?.sequence)
						Object.assign(values, { sequence: +values.sequence })
					if (queryParams.mode === 'add') {
						onAdd({
							...values,
							details: data
						})
							.then(handleClose)
							.finally(() => setIsSubmitting(false))
					} else if (queryParams.mode === 'edit_question') {
						onEdit({
							_id: record._id,
							...values,
							details: data
						})
							.then(handleClose)
							.finally(() => setIsSubmitting(false))
					}
				}
			})
		})
	}

	useEffect(() => {
		if (
			questionTypes.length &&
			queryParams.mode === 'edit_question' &&
			queryParams?.record_id
		) {
			// TODO: fetch question types
			setIsFetching(true)
			fetchQuestion(queryParams.record_id, questionCategory)
				.then((data) => {
					const { details, ...rest } = data || {}
					if (rest.question_type.name === 'DW') {
						// const newChoices = []
						// details.choices.forEach((el) => {
						// 	const { priority, ...restChoiceDetails } = el
						// 	priority.forEach((pr) => {
						// 		newChoices[pr] = { ...restChoiceDetails }
						// 	})
						// })
						// details.choices = newChoices
						const temp = details.choices
							.reduce((res, cur) => {
								cur.priority.forEach((pr) => {
									res.push({ ...cur, priority: +pr })
								})
								return res
							}, [])
							.sort((a, b) => a.priority - b.priority)
						details.choices = temp
					}
					setRecord(data)
					questionFixedDataForm.setFieldsValue(rest)
					questionDetailForm.setFieldsValue(details)
					const selectedQT = questionTypes.find(
						(el) => el._id === data.question_type._id
					)
					setSelectedType(selectedQT)
				})
				.catch(() => {
					message.error("Couldn't fetch question data")
					handleClose()
				})
				.finally(() => setIsFetching(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		questionTypes,
		fetchQuestion,
		modalIsVisible,
		questionCategory,
		handleClose,
		questionFixedDataForm,
		questionDetailForm
	])

	useEffect(() => {
		if (selectedType && queryParams.mode === 'edit_question') {
			setRecord({
				...restQuestionData,
				question_type: selectedType,
				type: selectedType._id
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType])

	const handleDelete = () => {
		onDelete(record._id, questionCategory).then(onClose)
	}

	return (
		<Modal
			visible={modalIsVisible}
			title={<h3 className="font-bold">{title}</h3>}
			onCancel={handleClose}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			className="w-full"
			// okText={ctaBtnText}
			// onOk={handleSubmit}
			// okButtonProps={{
			// 	className: 'inline-flex items-center justify-center leading-none'
			// }}
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						type="primary"
						onClick={handleSubmit}
						className="inline-flex items-center justify-center leading-none"
					>
						{ctaBtnText}
					</Button>
				</div>
			}
		>
			<Spin spinning={isFetching}>
				{questionCategory === 'admin_question' && (
					<MicroLessonDataSelector
						formInstance={questionFixedDataForm}
						modalIsVisible={modalIsVisible}
						isFetching={isFetching}
						subjects={subjects}
						subjectsIsLoading={subjectsIsLoading}
						questionTypes={questionTypes}
						questionTypesIsLoading={questionTypesIsLoading}
						setSelectedType={setSelectedType}
						initialValues={restQuestionData}
						mode={queryParams.mode}
						resetQuestionDetails={() => questionDetailForm.resetFields()}
					/>
				)}
				{questionCategory === 'admin_quiz' && (
					<QuizQuestionDataSelector
						formInstance={questionFixedDataForm}
						questionTypes={questionTypes}
						questionTypesIsLoading={questionTypesIsLoading}
						setSelectedType={setSelectedType}
						initialValues={restQuestionData}
						resetQuestionDetails={() => questionDetailForm.resetFields()}
					/>
				)}
				<QuestionDetailsSelector
					formInstance={questionDetailForm}
					selectedTypeName={selectedType?.name}
					initialValues={record?.details}
				/>
			</Spin>
		</Modal>
	)
}
QuestionModal.propTypes = {
	isVisible: bool,
	// setVisible: func.isRequired,
	questionCategory: oneOf(['admin_question', 'admin_quiz']).isRequired,
	onClose: func.isRequired,
	onAdd: func,
	onEdit: func,
	onDelete: func,
	// eslint-disable-next-line react/forbid-prop-types
	title: string.isRequired,
	ctaBtnText: string.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired,
	questionTypes: arrayOf(
		shape({
			_id: string,
			name: string
		})
	).isRequired,
	questionTypesIsLoading: bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired
}

QuestionModal.defaultProps = {
	isVisible: undefined,
	onAdd: undefined,
	onEdit: undefined,
	onDelete: undefined
}

import { useState } from 'react'
import {
	EyeInvisibleOutlined,
	EyeOutlined,
	LockOutlined,
	MailOutlined,
	UserOutlined
} from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'

import { useUser } from '../../store/hooks'
import { confirmExit } from '../../utils'
import { Icon } from '../icon'

export function AssignNewAdminBtn() {
	const user = useUser()
	const [form] = Form.useForm()
	const [modalIsVisible, setModalVisible] = useState(false)
	const openModal = () => setModalVisible(true)

	const closeModal = () => {
		form.resetFields()
		setModalVisible(false)
	}

	const handleCancel = () => {
		const formIsEmpty = Object.values(form.getFieldsValue()).every((el) => !el)
		confirmExit(closeModal, !formIsEmpty)
	}

	const [passwordIsVisible, setPasswordVisible] = useState(false)

	// eslint-disable-next-line no-unused-vars
	const togglePasswordVisibility = () =>
		setPasswordVisible((prevState) => !prevState)

	const addNewAdmin = () => {
		form.validateFields().then((values) => {
			const { name, email, password } = values
			user.assignNewAdmin({ name, email, password }).then(() => {
				closeModal()
			})
		})
	}

	return (
		<>
			<Button
				type="text"
				className="flex flex-row items-center justify-center"
				onClick={openModal}
			>
				<Icon
					variant="add"
					width={12}
					height={12}
					className="inline-block m-1"
				/>
				<span className="inline-block leading-none text-xs">
					Assign new admin
				</span>
			</Button>
			<Modal
				visible={modalIsVisible}
				title={<h3 className="m-0 font-bold">Assign New Admin</h3>}
				okType="primary"
				okText="Submit"
				onOk={addNewAdmin}
				onCancel={handleCancel}
				destroyOnClose
			>
				<Form
					form={form}
					onFinish={addNewAdmin}
					// autoComplete="off"
					name="assign_new_admin"
					labelCol={{
						span: 7
					}}
					initialValues={{
						name: '',
						email: '',
						password: '',
						'confirm-password': ''
					}}
				>
					<input type="hidden" value="something" />
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: 'Please enter a name for new admin' }
						]}
					>
						<Input autoFocus type="text" prefix={<UserOutlined />} />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{ required: true, message: 'Please enter an email address' },
							{ type: 'email', message: 'Email is not valid' }
						]}
					>
						<Input type="text" prefix={<MailOutlined />} autoComplete="email" />
					</Form.Item>
					<Form.Item
						name="password"
						hasFeedback
						label="Password"
						rules={[
							{
								required: true,
								message: 'Please enter a password'
							},
							{
								pattern: /[0-9a-zA-Z?!@#$%^&*-_]/,
								message:
									'Only numbers, english letters and special characters is allowed'
							}
						]}
					>
						<Input
							allowClear
							autoComplete="new-password"
							dir="ltr"
							type={passwordIsVisible ? 'text' : 'password'}
							prefix={<LockOutlined />}
							suffix={
								<button
									type="button"
									className="focus:text-blue-500 ml-1 flex items-center justify-center outline-none focus:outline-black border-none cursor-pointer bg-inherit"
									onClick={togglePasswordVisibility}
								>
									{passwordIsVisible ? (
										<EyeOutlined />
									) : (
										<EyeInvisibleOutlined />
									)}
								</button>
							}
						/>
					</Form.Item>
					<Form.Item
						name="confirm-password"
						label="Confirm Password"
						hasFeedback
						dependencies={['password']}
						rules={[
							{
								required: true,
								message: 'Please re-enter password'
							},
							({ getFieldValue }) => ({
								validator(_rule, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve()
									}
									return Promise.reject(Error('Passwords do not match'))
								}
							})
						]}
					>
						<Input
							allowClear
							dir="ltr"
							type={passwordIsVisible ? 'text' : 'password'}
							prefix={<LockOutlined />}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default AssignNewAdminBtn

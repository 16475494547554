import { Icon } from '../icon'

export const sidebarItems = [
	{
		name: 'Dashboard',
		key: '/',
		icon: (
			<Icon variant="dashboard" width={24} height={24} fill="currentColor" />
		)
	},
	{
		name: 'Question Types',
		key: '/question-types',
		icon: (
			<Icon
				variant="question-types"
				width={24}
				height={24}
				fill="currentColor"
			/>
		)
	},
	{
		name: 'Subjects',
		key: '/subjects',
		icon: <Icon variant="subjects" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Levels',
		key: '/levels',
		icon: <Icon variant="levels" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Lessons',
		key: '/lessons',
		icon: <Icon variant="lessons" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Micro Lessons',
		key: '/micro-lessons',
		icon: (
			<Icon
				variant="micro-lessons"
				width={24}
				height={24}
				fill="currentColor"
			/>
		)
	},
	{
		name: 'Revision Lessons',
		key: '/revision-lessons',
		icon: (
			<Icon
				variant="revision-lessons"
				width={24}
				height={24}
				fill="currentColor"
			/>
		)
	},
	{
		name: 'Quiz Questions',
		key: '/quiz-questions',
		icon: (
			<Icon
				variant="quiz-questions"
				width={24}
				height={24}
				fill="currentColor"
			/>
		)
	},
	{
		name: 'Trophies',
		key: '/trophies',
		icon: <Icon variant="trophies" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Avatars',
		key: '/avatars',
		icon: <Icon variant="avatars" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Gifts',
		key: '/gifts',
		icon: (
			<Icon
				variant="gift-box"
				width={24}
				height={24}
				fill="currentColor"
				className="mr-3"
			/>
		)
	},
	{
		name: 'Gift Donations',
		key: '/gift-donations',
		icon: (
			<Icon
				variant="gift-box"
				width={24}
				height={24}
				fill="currentColor"
				className="mr-3"
			/>
		)
	},
	{
		name: 'Bug Reports',
		key: '/bug-reports',
		icon: <Icon variant="help" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Users',
		key: '/users',
		icon: <Icon variant="users" width={24} height={24} fill="currentColor" />
	},
	{
		name: 'Logout',
		key: 'logout',
		icon: <Icon variant="exit" width={24} height={24} fill="currentColor" />
	}
]
export default sidebarItems

/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react'
import { Form, Select, Spin, Switch } from 'antd'
import { arrayOf, bool, func, object, shape, string } from 'prop-types'

import { quizLevels } from '../../utils'

export default function QuestionFixedDataSelector({
	formInstance,
	questionTypes,
	questionTypesIsLoading,
	setSelectedType,
	initialValues,
	resetQuestionDetails
}) {
	useEffect(() => {
		if (Object.keys(initialValues).length > 0) {
			formInstance.setFieldsValue({
				...initialValues,
				status: !!(initialValues.status === 'active'),
				level: +initialValues?.level
			})
		}
	}, [formInstance, initialValues])

	return (
		<Form
			form={formInstance}
			name="select_quiz_question_data"
			initialValues={{
				status: true
			}}
		>
			<div className="w-full flex flex-row">
				<Form.Item
					name="level"
					label="Level"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select level of question'
						}
					]}
				>
					<Select>
						{quizLevels?.map((level) => (
							<Select.Option value={level.id} key={level.id}>
								{level.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="type"
					label="Question Type"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a question type for micro-lesson'
						}
					]}
				>
					<Select
						notFoundContent={
							questionTypesIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(value) => {
							resetQuestionDetails()
							const selectedQT = questionTypes.find((el) => el._id === value)
							setSelectedType(selectedQT)
						}}
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{questionTypes?.map((qt) => (
							<Select.Option value={qt._id} key={qt._id}>
								{qt.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="status"
					label="Active"
					colon={false}
					labelAlign="left"
					className="flex flex-col flex-1"
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>
			</div>
		</Form>
	)
}
QuestionFixedDataSelector.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	questionTypes: arrayOf(
		shape({
			_id: string,
			name: string
		})
	).isRequired,
	questionTypesIsLoading: bool.isRequired,
	setSelectedType: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	resetQuestionDetails: func.isRequired
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, InputNumber, message, Modal, Spin } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object } from 'prop-types'

import { SingleFileUploader } from '../../components'
import { useTrophies } from '../../hooks'
import { confirmExit } from '../../utils'

const NOT_EDITABLE = [1, 2, 3, 4]

export default function TrophyModal({ onClose, onEdit, queryParams }) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)

	const { fetchTrophy } = useTrophies()
	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form

	const isNotEditable = useMemo(() => NOT_EDITABLE.includes(record?.order), [
		record?.order
	])

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		const { _id, ...rest } = record || {}
		const fieldsValues = getFieldsValue()
		setFormHasChanged(!isEqual(fieldsValues, rest))
	}, [getFieldsValue, record])

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)
			const { icon, ...payload } = values
			Object.assign(payload, { _id: record._id })
			if (icon !== record.icon) Object.assign(payload, { icon })

			onEdit(payload)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchTrophy(queryParams.record_id)
				.then((data) => {
					setRecord(data)
					setFieldsValue(data)
				})
				.catch(() => {
					message.error("Couldn't fetch trophy data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		fetchTrophy,
		modalIsVisible,
		onClose,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={modalIsVisible}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold mb-0">Edit Trophy</h3>}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			centered
			okText="Save"
			onOk={handleSubmitEdit}
			okButtonProps={{
				disabled: !formHasChanged,
				className: 'inline-flex flex-row items-center justify-center'
			}}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="edit_trophy"
					labelCol={{
						span: 5
					}}
					onFieldsChange={checkFormChanged}
					preserve={false}
				>
					<Form.Item name="order" label="Order">
						<InputNumber readOnly />
					</Form.Item>
					<Form.Item name="name" label="Name">
						<Input readOnly />
					</Form.Item>
					<Form.Item name="description" label="Description">
						<Input readOnly />
					</Form.Item>
					<Form.Item name="level1" label="Level 1">
						{isNotEditable ? <Input readOnly /> : <InputNumber />}
					</Form.Item>
					<Form.Item name="level2" label="Level 2">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level3" label="Level 3">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level4" label="Level 4">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level5" label="Level 5">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item
						name="icon"
						key="icon"
						label="Icon"
						rules={[
							{
								required: true,
								message: 'Please select icon file of trophy'
							}
						]}
						className="flex flex-row items-center"
						getValueFromEvent={() => record?.icon}
					>
						<div>
							<SingleFileUploader
								type="other"
								onlyPNG
								defaultFile={record?.icon}
								afterUpload={(fileID) => {
									setFieldsValue({ icon: fileID })
									checkFormChanged()
								}}
							/>
						</div>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
TrophyModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired
}

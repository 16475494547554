/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { Form, Select, Spin } from 'antd'
// import cn from 'classnames'
import { arrayOf, bool, func, object, shape, string } from 'prop-types'
import qs from 'query-string'

import { useLessons, useLevels } from '../../hooks'

const INITIAL_STATE = {
	selectedSubject: undefined,
	selectedLevel: undefined,
	filteredLevels: [],
	filteredLessons: []
}
export default function RevisionLessonForm({
	// active,
	formInstance,
	subjects,
	subjectsIsLoading,
	initialValues,
	questionTypes,
	questionTypesIsLoading,
	setSelectedType
}) {
	const [state, setState] = useState(INITIAL_STATE)

	const updateState = (newState) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}
	const resetState = () => setState(INITIAL_STATE)

	const location = useLocation()

	const modalIsVisible = useMemo(
		() => qs.parse(location.search)?.modal_is_open,
		[location.search]
	)

	useEffect(() => {
		if (!modalIsVisible) {
			resetState()
		}
	}, [modalIsVisible])

	const { isLoading: levelsIsLoading, fetchLevels } = useLevels()
	const { isLoading: lessonsIsLoading, fetchLessons } = useLessons()

	const filterLevels = useCallback(
		(subjectID) => {
			fetchLevels({ subject_id: subjectID }).then((levels) => {
				updateState({ filteredLevels: levels || [] })
			})
		},
		[fetchLevels]
	)

	const filterLessons = useCallback(
		(levelID) => {
			fetchLessons({ level_id: levelID, size: 9999 }).then((lessons) => {
				updateState({ filteredLessons: lessons || [] })
			})
		},
		[fetchLessons]
	)

	useEffect(() => {
		if (state.selectedSubject) {
			filterLevels(state.selectedSubject)
		}
	}, [filterLevels, state.selectedSubject])

	useEffect(() => {
		if (state.selectedLevel) {
			filterLessons(state.selectedLevel)
		}
	}, [filterLessons, state.selectedLevel])

	useEffect(() => {
		if (initialValues && Object.keys(initialValues).length) {
			const {
				// status,
				type,
				screen,
				details,
				revision: {
					lesson: {
						_id: lesson_id,
						level: {
							_id: level_id,
							subject: { _id: subject_id }
						}
					}
				}
			} = initialValues || {}
			updateState({
				selectedSubject: subject_id,
				selectedLevel: level_id
			})
			formInstance.setFieldsValue({
				// status: !!(status === 'active'),
				question: {
					type,
					screen,
					details
				},
				lesson_id
			})
		} else {
			updateState({
				selectedSubject: undefined,
				selectedLevel: undefined
			})
		}
	}, [formInstance, initialValues])

	return (
		<Form
			form={formInstance}
			name="revision_lesson_data_form"
			initialValues={{
				lesson_id: undefined,
				question: {
					// status: true,
					type: undefined,
					screen: undefined
				}
			}}
			preserve={false}
			// className={cn({ hidden: !active })}
		>
			<div className="w-full flex flex-row">
				<Form.Item
					label="Subject"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Select
						value={state.selectedSubject}
						notFoundContent={
							subjectsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							updateState({
								selectedSubject: e,
								selectedLevel: undefined,
								filteredLevels: [],
								filteredLessons: []
							})
							formInstance.setFieldsValue({
								level_id: undefined,
								lesson_id: undefined
							})
						}}
					>
						{subjects?.map((subject) => (
							<Select.Option value={subject._id} key={subject._id}>
								{subject.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label="Level"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Select
						disabled={!state.selectedSubject}
						value={state.selectedLevel}
						notFoundContent={
							levelsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							updateState({ selectedLevel: e, filteredLessons: [] })
							formInstance.setFieldsValue({ lesson_id: undefined })
						}}
					>
						{state.filteredLevels.map((level) => (
							<Select.Option value={level._id} key={level._id}>
								{level.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="lesson_id"
					label="Lesson"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a lesson for micro-lesson'
						}
					]}
				>
					<Select
						disabled={!state.selectedLevel}
						notFoundContent={
							lessonsIsLoading ? <Spin size="small" /> : undefined
						}
					>
						{state.filteredLessons.map((lesson) => (
							<Select.Option value={lesson._id} key={lesson._id}>
								{lesson.priority} - {lesson.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</div>
			<div className="w-full flex flex-row">
				<Form.Item
					name={['question', 'type']}
					label="Question Type"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a question type for micro-lesson'
						}
					]}
				>
					<Select
						notFoundContent={
							questionTypesIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(value) => {
							setSelectedType(questionTypes.find((el) => el._id === value))
						}}
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{questionTypes?.map((qt) => (
							<Select.Option value={qt._id} key={qt._id}>
								{qt.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				{/* <Form.Item
					name={['question', 'screen']}
					label="Revision Screen NO."
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please input sequence of question'
						},
						{
							min: 1,
							pattern: /^(?![0]$)\d*$/,
							message: 'Only positive integers'
						}
					]}
				>
					<InputNumber className="w-full" />
				</Form.Item> */}
				{/* <Form.Item
				name={['question', 'status']}
				label="Active"
				labelAlign="left"
				className="flex flex-col flex-1"
				valuePropName="checked"
			>
				<Switch />
			</Form.Item> */}
			</div>
		</Form>
	)
}
RevisionLessonForm.propTypes = {
	// active: bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired,
	questionTypes: arrayOf(
		shape({
			_id: string,
			name: string
		})
	).isRequired,
	questionTypesIsLoading: bool.isRequired,
	setSelectedType: func.isRequired
}

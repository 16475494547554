/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

export default function useGifts() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchGifts = useCallback(
		/**
		 * @param {object} queryParams
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin_gift?${qs.stringify(queryParams, {
					skipNull: true
				})}`

				axios
					.get(endpoint)
					.then(({ data: gifts }) => {
						const { data: giftsData, total_count } = gifts
						setTotalCount(total_count)
						setData(giftsData)
						resolve(giftsData)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchGift = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				axios
					.get(`/admin_gift/${id}`)
					.then(({ data: giftData }) => resolve(giftData))
					.catch(reject)
			})
		},
		[]
	)

	/**
	 * @typedef {object} Options
	 * @property {bool} [refetchOnSuccess]
	 */
	const addNewGift = useCallback(
		/**
		 * @param {*} payload
		 * @param {Options} [options]
		 */
		(payload, options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				const key = Date.now()
				message.loading({
					content: 'adding new gift...',
					key
				})
				axios
					.post('/admin_gift', payload)
					.then(({ data: newGift }) => {
						message.success({
							content: 'new gift added',
							key
						})
						enableUpdateStatistics()
						if (refetchOnSuccess) fetchGifts(qs.parse(search))
						resolve(newGift)
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new gift',
							key
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	const deleteGift = useCallback(
		/**
		 * @param {string} id
		 * @param {Options} [options]
		 */
		(id, options) => {
			return new Promise((resolve, reject) => {
				if (id) {
					const { refetchOnDone = true } = options || {}
					message.loading({
						content: 'deleting gift...',
						key: id
					})
					axios
						.delete(`/admin_gift/${id}`)
						.then(() => {
							message.success({
								content: 'gift deleted',
								key: id
							})
							enableUpdateStatistics()
							if (refetchOnDone) fetchGifts(qs.parse(search))
							resolve()
						})
						.catch((err) => {
							message.error({
								content: 'failed to delete gift',
								key: id
							})
							reject(err)
						})
				} else reject(new Error('gift ID not provided'))
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	const editGift = useCallback(
		/**
		 * @param {*} payload
		 * @param {Options} [options]
		 */
		(payload, options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				const { _id } = payload || {}
				message.loading({
					content: 'updating gift data...',
					key: _id
				})
				axios
					.put('/admin_gift', payload)
					.then(({ data: giftData }) => {
						message.success({
							content: 'gift data updated',
							key: _id
						})
						enableUpdateStatistics()
						if (refetchOnSuccess) fetchGifts(qs.parse(search))
						resolve(giftData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update gift data',
							key: _id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	return {
		data,
		isLoading,
		totalCount,
		fetchGifts,
		editGift,
		deleteGift,
		addNewGift,
		fetchGift
	}
}

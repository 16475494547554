import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import { element } from 'prop-types'

import { store } from '../store'

const MainProvider = ({ children }) => {
	return (
		<Provider store={store}>
			<ConfigProvider>{children}</ConfigProvider>
		</Provider>
	)
}

MainProvider.propTypes = {
	children: element.isRequired
}
export default MainProvider
export { MainProvider }

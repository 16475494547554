/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react'
import { Form, InputNumber, Select, Spin, Switch } from 'antd'
import { arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types'

import { useLessons, useLevels } from '../../hooks'
import { axios } from '../../utils'

export default function QuestionFixedDataSelector({
	formInstance,
	subjects,
	subjectsIsLoading,
	questionTypes,
	questionTypesIsLoading,
	setSelectedType,
	mode,
	initialValues,
	resetQuestionDetails,
	modalIsVisible,
	isFetching
}) {
	const [state, setState] = useState({
		selectedSubject: undefined,
		selectedLevel: undefined,
		filteredLevels: [],
		filteredLessons: []
	})
	const updateState = (newState) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}

	const { isLoading: levelsIsLoading, fetchLevels } = useLevels()
	const { isLoading: lessonsIsLoading, fetchLessons } = useLessons()

	const filterLevels = useCallback(
		(subjectID) => {
			fetchLevels({ subject_id: subjectID }).then((levels) => {
				updateState({ filteredLevels: levels || [] })
			})
		},
		[fetchLevels]
	)

	const filterLessons = useCallback(
		(levelID) => {
			fetchLessons({ level_id: levelID, size: 9999 }).then((lessons) => {
				updateState({ filteredLessons: lessons || [] })
			})
		},
		[fetchLessons]
	)

	useEffect(() => {
		if (state.selectedSubject) {
			filterLevels(state.selectedSubject)
		}
	}, [filterLevels, state.selectedSubject])

	useEffect(() => {
		if (state.selectedLevel) {
			filterLessons(state.selectedLevel)
		}
	}, [filterLessons, state.selectedLevel])

	const [loadingSequence, setLoadingSequence] = useState(false)
	const [sequence, setSequence] = useState(null)

	const onChangeLesson = (val) => {
		const lessonPriority = state.filteredLessons.find((el) => el._id === val)
			?.priority
		if (lessonPriority !== undefined && state.selectedSubject) {
			setLoadingSequence(true)
			axios
				.get(
					`/admin_question?subject_id=${state.selectedSubject}&lesson_priority=${lessonPriority}&size=9999`
				)
				.then(({ data: { data: questions } }) => {
					const seq =
						questions?.sort((a, b) => a.sequence - b.sequence)?.[
							questions.length - 1
						]?.sequence + 1 || 1
					formInstance.setFieldsValue({ sequence: seq })
					setSequence(seq)
				})
				.finally(() => setLoadingSequence(false))
		}
	}

	useEffect(() => {
		if (modalIsVisible) {
			if (
				Object.keys(initialValues).length &&
				mode === 'edit_question' &&
				isFetching
			) {
				const {
					status,
					sequence: seq,
					lesson: {
						level: {
							_id: level_id,
							subject: { _id: subject_id }
						}
					}
				} = initialValues || {}
				updateState({
					selectedSubject: subject_id,
					selectedLevel: level_id
				})
				setSequence(seq)
				formInstance.setFieldsValue({
					...initialValues,
					status: !!(status === 'active'),
					subject_id,
					level_id,
					sequence: seq
				})
			}
		} else {
			updateState({
				selectedSubject: undefined,
				selectedLevel: undefined
			})
			setSequence(null)
			formInstance.setFieldsValue({ sequence: undefined })
		}
	}, [formInstance, initialValues, isFetching, modalIsVisible, mode])

	return (
		<Form
			form={formInstance}
			name="select_micro_lesson_data"
			initialValues={{
				status: true
			}}
		>
			<div className="w-full flex flex-row">
				<Form.Item
					name="subject_id"
					label="Subject"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Select
						value={state.selectedSubject}
						disabled={mode === 'edit_question'}
						notFoundContent={
							subjectsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							updateState({
								selectedSubject: e,
								selectedLevel: undefined,
								filteredLevels: [],
								filteredLessons: []
							})
							formInstance.setFieldsValue({
								level_id: undefined,
								lesson_id: undefined,
								sequence: undefined
							})
							setSequence(null)
						}}
					>
						{subjects?.map((subject) => (
							<Select.Option value={subject._id} key={subject._id}>
								{subject.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="level_id"
					label="Level"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Select
						disabled={!state.selectedSubject || mode === 'edit_question'}
						notFoundContent={
							levelsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							updateState({ selectedLevel: e, filteredLessons: [] })
							formInstance.setFieldsValue({
								lesson_id: undefined,
								sequence: undefined
							})
							setSequence(null)
						}}
					>
						{state.filteredLevels.map((level) => (
							<Select.Option value={level._id} key={level._id}>
								{level.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="lesson_id"
					label="Lesson"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a lesson for micro-lesson'
						}
					]}
				>
					<Select
						disabled={!state.selectedLevel || mode === 'edit_question'}
						notFoundContent={
							lessonsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={onChangeLesson}
					>
						{state.filteredLessons.map((lesson) => (
							<Select.Option value={lesson._id} key={lesson._id}>
								{lesson.priority} - {lesson.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</div>
			<div className="w-full flex flex-row">
				<Form.Item
					name="type"
					label="Question Type"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a question type for micro-lesson'
						}
					]}
				>
					<Select
						notFoundContent={
							questionTypesIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(value) => {
							setSelectedType(questionTypes.find((el) => el._id === value))
							resetQuestionDetails()
						}}
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{questionTypes?.map((qt) => (
							<Select.Option value={qt._id} key={qt._id}>
								{qt.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="sequence"
					label="Micro Lesson NO."
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please input sequence of question'
						},
						{
							min: 1,
							pattern: /^(?![0]$)\d*$/,
							message: 'Only positive integers'
						}
					]}
				>
					<Spin spinning={loadingSequence}>
						<InputNumber
							disabled={mode === 'edit_question'}
							value={sequence}
							onChange={(value) => {
								setSequence(value)
								formInstance.setFieldsValue({ sequence: value })
							}}
							className="w-full"
						/>
					</Spin>
				</Form.Item>
				<Form.Item
					name="status"
					label="Active"
					labelAlign="left"
					className="flex flex-col flex-1"
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>
			</div>
		</Form>
	)
}
QuestionFixedDataSelector.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired,
	questionTypes: arrayOf(
		shape({
			_id: string,
			name: string
		})
	).isRequired,
	questionTypesIsLoading: bool.isRequired,
	mode: oneOf(['add', 'edit_question']).isRequired,
	setSelectedType: func.isRequired,
	resetQuestionDetails: func.isRequired,
	modalIsVisible: bool.isRequired,
	isFetching: bool.isRequired
}

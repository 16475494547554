/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, InputNumber, message, Modal, Spin } from 'antd'
import isEqual from 'lodash.isequal'
import { bool, func, string } from 'prop-types'
import qs from 'query-string'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useGifts } from '../../hooks'
import { confirmExit } from '../../utils'

export default function GiftModal({
	isVisible,
	onClose,
	onAdd,
	onEdit,
	onDelete,
	title,
	ctaBtnText
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [record, setRecord] = useState(undefined)
	const [formHasChanged, setFormHasChanged] = useState(false)

	const { resetFields } = form
	const history = useHistory()
	const { fetchGift } = useGifts()

	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const checkFormChanged = useCallback(() => {
		const { _id, ...rest } = record || {}
		setFormHasChanged(!isEqual(form.getFieldsValue(), rest))
	}, [form, record])

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			if (queryParams.mode === 'add') {
				onAdd(values)
					.then(onClose)
					.finally(() => setIsSubmitting(false))
			} else if (queryParams.mode === 'edit') {
				const { image, ...payload } = values
				Object.assign(payload, { _id: record._id })
				if (image !== record.image) Object.assign(payload, { image })

				onEdit(payload)
					.then(onClose)
					.finally(() => setIsSubmitting(false))
			}
		})
	}
	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	useEffect(() => {
		if (queryParams.mode === 'edit' && queryParams?.record_id) {
			setIsFetching(true)
			fetchGift(queryParams.record_id)
				.then((data) => {
					setRecord(data)
				})
				.catch(() => {
					message.error("Couldn't fetch gift data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchGift, queryParams.mode, queryParams.Gifts])

	useEffect(() => {
		if (record && Object.keys(record).length) {
			form.setFieldsValue(record)
		}
	}, [form, record])

	const handleDelete = () => {
		onDelete(record._id).then(onClose)
	}

	return (
		<Modal
			visible={
				isVisible !== undefined
					? isVisible
					: !!queryParams?.modal_is_open || false
			}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">{title}</h3>}
			afterClose={afterClose}
			forceRender
			destroyOnClose
			className="w-full"
			onCancel={handleCancelEdit}
			// okText={ctaBtnText}
			// onOk={handleSubmit}
			// okButtonProps={{
			// 	className: 'inline-flex items-center justify-center leading-none'
			// }}
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleCancelEdit}>Cancel</Button>
					<Button
						type="primary"
						onClick={handleSubmit}
						className="inline-flex items-center justify-center leading-none"
					>
						{ctaBtnText}
					</Button>
				</div>
			}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="gift_modal"
					preserve={false}
					labelCol={{
						span: 2
					}}
				>
					<Form.Item
						name="title"
						label="Title"
						rules={[
							{
								required: true,
								message: 'Please input title of gift'
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="description"
						label="Description"
						rules={[
							{
								required: true,
								message: 'Please input description of gift'
							}
						]}
					>
						<Input.TextArea rows={4} />
					</Form.Item>
					<Form.Item
						name="coins"
						label="Coins"
						rules={[
							{
								required: true,
								message: 'Please input coins amount for this gift'
							},
							{
								min: 1,
								pattern: /^(?![0]$)\d*$/,
								message: 'Only positive integers'
							}
						]}
					>
						<InputNumber />
					</Form.Item>
					<Form.Item
						name="image"
						key="image"
						label="Image"
						rules={[
							{
								required: true,
								message: 'Please select image file of gift'
							}
						]}
						className="flex flex-row items-center"
						getValueFromEvent={() => record?.image}
					>
						<div>
							<SingleFileUploader
								type="other"
								defaultFile={record?.image || undefined}
								afterUpload={(fileID) => {
									form.setFieldsValue({ image: fileID })
									checkFormChanged()
								}}
							/>
						</div>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
GiftModal.propTypes = {
	isVisible: bool,
	// setVisible: func.isRequired,
	onClose: func.isRequired,
	onAdd: func,
	onEdit: func,
	onDelete: func,
	title: string,
	ctaBtnText: string
}

GiftModal.defaultProps = {
	isVisible: undefined,
	onAdd: undefined,
	onEdit: undefined,
	onDelete: undefined,
	title: '',
	ctaBtnText: ''
}

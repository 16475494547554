/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} RespondToReportPayload
 * @property {string} _id
 * @property {string} respond
 */

export default function useReports() {
	const [data, setData] = useState({
		reports: [],
		totalCount: 0
	})
	const [isLoading, setLoading] = useState(true)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()
	const fetchReports = useCallback(
		/**
		 * @param {object} queryParams
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin_bug_report?${qs.stringify(queryParams, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: { data: reports, total_count } }) => {
						setData({
							reports: reports.map((el) => {
								return {
									...el,
									record_id: {
										lesson: el.lesson_id,
										revision: el.revision_id,
										micro_lesson: el.question_id,
										quiz: el.question_id
									}[el.type]
								}
							}),
							totalCount: total_count
						})
						resolve(reports)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchReport = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`report id is not provided`))
				} else {
					axios
						.get(`/admin_bug_report/${id}`)
						.then(({ data: reportData }) => {
							const { lesson_id, question_id, revision_id } = reportData
							const res = {
								...reportData,
								record_id: lesson_id || question_id || revision_id
							}
							resolve(res)
						})
						.catch(reject)
				}
			})
		},
		[]
	)

	const respondToReport = useCallback(
		/**
		 * @param {RespondToReportPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { _id: report_id } = payload
				message.loading({
					content: 'submitting report response...',
					key: report_id
				})

				axios
					.put('/admin_bug_report', payload)
					.then(({ data: reportData }) => {
						message.success({
							content: 'your response submitted successfully',
							key: report_id
						})
						enableUpdateStatistics()
						fetchReports(qs.parse(search))
						resolve(reportData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to submit your response',
							key: report_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchReports, search]
	)

	return {
		data: data.reports,
		totalCount: data.totalCount,
		isLoading,
		fetchReports,
		fetchReport,
		respondToReport
	}
}

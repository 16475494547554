/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, Input, InputNumber, Modal, Switch } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object } from 'prop-types'

import { DeleteBtn } from '../../components'
import { confirmExit } from '../../utils'

export default function EditLevelModal({
	onClose,
	onEdit,
	onDelete,
	queryParams
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const { resetFields, validateFields, getFieldsValue, setFieldsValue } = form
	const [record, setRecord] = useState(undefined)

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode } = queryParams || {}
		return modal_is_open && mode === 'edit'
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		setFormHasChanged(
			!isEqual(getFieldsValue(), {
				title: record.title,
				index: record.index
			})
		)
	}, [getFieldsValue, record])

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)
			onEdit(values)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleDelete = () => {
		onDelete(record.index).then(onClose)
	}
	useEffect(() => {
		if (modalIsVisible) {
			const activeRecord = JSON.parse(
				sessionStorage.getItem('active_edit_level')
			)
			if (activeRecord) {
				setRecord(activeRecord)
				setFieldsValue(activeRecord)
			} else onClose()
		} else {
			sessionStorage.removeItem('active_edit_level')
		}
		// return () => localStorage.removeItem('active_edit_level')
	}, [onClose, modalIsVisible, setFieldsValue])

	return (
		<Modal
			visible={modalIsVisible}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold">Edit Level</h3>}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			centered
			// okText="Edit"
			// onOk={handleSubmitEdit}
			// okButtonProps={{
			// 	disabled: !formHasChanged,
			// 	className: 'inline-flex flex-row items-center justify-center'
			// }}
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleCancelEdit}>Cancel</Button>
					<Button
						type="primary"
						disabled={!formHasChanged}
						onClick={handleSubmitEdit}
						className="inline-flex flex-row items-center justify-center"
					>
						Save
					</Button>
				</div>
			}
		>
			<Form
				form={form}
				name="edit_level"
				labelCol={{
					span: 5
				}}
				preserve={false}
				onFieldsChange={checkFormChanged}
			>
				<Form.Item name="index" label="Index">
					<InputNumber disabled />
				</Form.Item>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title of level'
						}
					]}
				>
					<Input autoFocus />
				</Form.Item>
				<Form.Item name="active" label="Active" valuePropName="checked">
					<Switch />
				</Form.Item>
			</Form>
		</Modal>
	)
}
EditLevelModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	onDelete: func.isRequired,
	queryParams: object.isRequired
}

import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import classnames from 'classnames'

import { useSidebar } from '../../store/hooks'
import { logoutConfirm } from '../../utils'
import { Icon } from '../icon'

import { sidebarItems } from './items'

const { Sider } = Layout

export function SideBar() {
	const history = useHistory()
	const location = useLocation()

	const [selectedItem, setSelectedItem] = useState(location.pathname)
	const sidebar = useSidebar()

	const toggleSidebarVisibility = () => {
		sidebar.setIsCollapsed(!sidebar.isCollapsed)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		setSelectedItem(location.pathname)
	}, [location])

	const handleClick = (e) => {
		if (e.key === 'logout') {
			logoutConfirm()
		} else if (e.key === 'collapse-menu-btn') {
			// do nothing
		} else {
			history.push(e.key)
		}
	}

	return (
		<>
			<Helmet>
				<body
					className={classnames(
						'transition-all duration-350',
						sidebar.isCollapsed ? 'pl-14' : 'pl-14 sm:pl-48'
					)}
				/>
			</Helmet>
			<Sider
				theme="light"
				collapsible
				collapsed={sidebar.isCollapsed}
				onCollapse={sidebar.setIsCollapsed}
				className={classnames(
					'fixed z-50 top-0 left-0 bottom-0 h-screen min-w-min max-w-full transition-all duration-300 overflow-y-auto overflow-x-hidden',
					sidebar.isCollapsed ? 'w-14' : 'w-screen sm:w-48'
				)}
				trigger={null}
			>
				<Menu
					selectedKeys={[selectedItem]}
					mode="inline"
					className="flex flex-col justify-between items-center w-full h-full px-2"
					onClick={handleClick}
				>
					{sidebarItems.map((item) => (
						<Menu.Item
							key={item.key}
							icon={item.icon}
							className="h-8 flex items-center px-2 my-1"
							style={{
								minHeight: '2rem'
							}}
						>
							{item.name}
						</Menu.Item>
					))}
					<Menu.Item
						className="mt-auto flex items-center justify-center w-full p-0"
						key="collapse-menu-btn"
						title={`${sidebar.isCollapsed ? 'Fold-in' : 'Fold-out'} menu`}
						onClick={toggleSidebarVisibility}
						style={{
							minHeight: '2rem'
						}}
						icon={
							<Icon
								variant={sidebar.isCollapsed ? 'menu-open' : 'menu-close'}
								width={18}
								height={18}
								fill="#000"
							/>
						}
					/>
				</Menu>
			</Sider>
		</>
	)
}

export default SideBar

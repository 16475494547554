/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	email: '',
	name: '',
	avatar: null,
	shouldUpdateStatistics: true,
	statistics: undefined
}

const userReducer = {
	updateState: (state, action) => ({ ...state, ...action.payload })
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: userReducer
})

export default userSlice

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, InputNumber, message, Modal, Spin, Switch } from 'antd'
import isEqual from 'lodash.isequal'
import { array, func, object } from 'prop-types'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useAvatars } from '../../hooks'
import { confirmExit } from '../../utils'

export default function EditAvatarModal({
	onClose,
	onEdit,
	onDelete,
	queryParams,
	avatarsData
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(true)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)

	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form
	const { fetchAvatar } = useAvatars()

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		const { _id, user_id, icon_dark, ...rest } = record || {}
		const fieldsValues = getFieldsValue()
		setFormHasChanged(!isEqual(fieldsValues, rest))
	}, [getFieldsValue, record])

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)
			const { icon_light, ...payload } = values
			Object.assign(payload, { _id: record?._id })
			;[
				'icon_light'
				// , 'icon_dark'
			].forEach((name) => {
				if (record?.[name] !== values[name])
					Object.assign(payload, { [name]: values[name] })
			})
			onEdit(payload)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchAvatar(queryParams.record_id)
				.then((data) => {
					setRecord(data)
					setFieldsValue(data)
				})
				.catch(() => {
					message.error("Couldn't fetch avatar data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		onClose,
		fetchAvatar,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	const handleDelete = () => {
		onDelete(record._id).then(onClose)
	}

	return (
		<>
			<Modal
				visible={modalIsVisible}
				onCancel={handleCancelEdit}
				title={<h3 className="font-bold mb-0">Edit Avatar</h3>}
				afterClose={afterClose}
				confirmLoading={isSubmitting}
				forceRender
				destroyOnClose
				// okText="Edit"
				// onOk={handleSubmitEdit}
				// okButtonProps={{
				// 	disabled: !formHasChanged,
				// 	className: 'inline-flex flex-row items-center justify-center'
				// }}
				footer={
					<div className="flex flex-row flex-nowrap">
						<DeleteBtn
							onDelete={handleDelete}
							type="text"
							className="mr-auto"
						/>
						<Button onClick={handleCancelEdit}>Cancel</Button>
						<Button
							type="primary"
							disabled={!formHasChanged}
							onClick={handleSubmitEdit}
							className="inline-flex items-center justify-center leading-none"
						>
							Save
						</Button>
					</div>
				}
			>
				<Spin spinning={isFetching}>
					<Form
						form={form}
						name="edit_avatar"
						labelCol={{
							span: 6
						}}
						preserve={false}
						onFieldsChange={checkFormChanged}
					>
						<Form.Item
							name="avatar_id"
							label="Avatar ID"
							rules={[
								{
									required: true,
									message: 'Please input an ID for avatar'
								},
								{
									validator: async (_, value) => {
										if (
											avatarsData.some(
												(el) => el.avatar_id === value && el._id !== record._id
											)
										) {
											return Promise.reject(
												new Error(`Avatar ID should be unique`)
											)
										}

										return Promise.resolve()
									}
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						<Form.Item
							name="avatar_category"
							label="Avatar Category"
							rules={[
								{
									required: true,
									message: 'Please input a category for avatar'
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						<Form.Item name="for_free" label="For Free" valuePropName="checked">
							<Switch />
						</Form.Item>
						<Form.Item name="in_shop" label="In Shop" valuePropName="checked">
							<Switch />
						</Form.Item>
						<Form.Item
							name="coins"
							label="Coins"
							rules={[
								{
									required: true,
									message: 'Please input the coins amount for purchase avatar'
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						<Form.Item
							name="gem_level"
							label="Gem Level"
							rules={[
								{
									required: true,
									message: 'Please input the gem level of avatar'
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						{[
							{
								name: 'icon_light',
								label: 'Icon'
							}
							/*
							,{
								name: 'icon_dark',
								label: 'Dark Icon'
							}
							*/
						].map((icon) => (
							<Form.Item
								name={icon.name}
								key={icon.name}
								label={icon.label}
								rules={[
									{
										required: icon.name === 'icon_light',
										message: 'Please select icon file of avatar'
									}
								]}
								getValueFromEvent={() => record?.[icon.name]}
								className="flex flex-row items-center"
							>
								<div>
									<SingleFileUploader
										type="avatar"
										onlyPNG
										defaultFile={record?.[icon.name]}
										afterUpload={(fileID) => {
											setFieldsValue({ [icon.name]: fileID })
											checkFormChanged()
										}}
									/>
								</div>
							</Form.Item>
						))}
					</Form>
				</Spin>
			</Modal>
		</>
	)
}

EditAvatarModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	onDelete: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	avatarsData: array.isRequired
}

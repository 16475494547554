/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Modal, Select, Spin } from 'antd'
import PropTypes, { arrayOf, bool, func, shape, string } from 'prop-types'

import { useLevels } from '../../hooks'
import { axios, confirmExit } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

const { Option } = Select

export default function AddNewLessonModal({
	visible,
	setVisible,
	onAdd,
	subjects,
	subjectsIsLoading
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()
	const [priority, setPriority] = useState(null)
	const [loadingPriority, setLoadingPriority] = useState(false)
	const { isLoading: levelsIsLoading, fetchLevels } = useLevels()

	const [filteredLevels, setFilteredLevels] = useState([])
	const [selectedSubject, setSelectedSubject] = useState()

	const onChangeLevel = (val) => {
		axios
			.get(`/admin_lesson?level_id=${val}&sort_priority=0&size=9999`)
			.then(({ data: { data: lessons } }) => {
				const prrty = lessons?.length ? +lessons[0]?.priority + 1 || null : 1
				setPriority(prrty)
				form.setFieldsValue({ priority: prrty })
			})
			.finally(() => setLoadingPriority(false))
	}
	useEffect(() => {
		if (selectedSubject) {
			fetchLevels({ subject_id: selectedSubject }).then((levels) => {
				setFilteredLevels(levels)
			})
		}
	}, [fetchLevels, selectedSubject])

	const closeModal = () => {
		form.resetFields()
		setVisible(false)
		setIsSubmitting(false)
	}

	const handleAddingNewLesson = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			onAdd(values)
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	const onCancel = () => {
		const formIsEmpty =
			Object.values({ ...form.getFieldsValue() }).every((el) => !el) &&
			!selectedSubject

		confirmExit(closeModal, !formIsEmpty)
	}

	useEffect(() => {
		if (visible) {
			setSelectedSubject()
			form.resetFields()
		}
	}, [form, visible])

	return (
		<Modal
			visible={visible}
			okText="ADD NEW LESSON"
			onOk={handleAddingNewLesson}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={onCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">New Lesson</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_lesson"
				initialValues={{
					level: null,
					title: '',
					icon_light: null,
					icon_dark: null
				}}
				labelCol={{
					span: 5
				}}
				onFinish={handleAddingNewLesson}
			>
				<Form.Item
					label="Subject"
					rules={[
						{
							required: true,
							message: 'Please select a subject for lesson'
						}
					]}
				>
					<Select
						value={selectedSubject}
						notFoundContent={
							subjectsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							setSelectedSubject(e)
							form.setFieldsValue({ level: undefined })
							setPriority(null)
						}}
					>
						{subjects.map((subject) => (
							<Option value={subject._id} key={subject._id}>
								{subject.title}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="level"
					label="Level"
					rules={[
						{
							required: true,
							message: 'Please select a level for lesson!'
						}
					]}
				>
					<Select
						disabled={!selectedSubject}
						notFoundContent={
							levelsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={onChangeLevel}
					>
						{filteredLevels.map((level) => (
							<Option value={level._id} key={level._id}>
								{level.title}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title of lesson'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="priority" label="Lesson NO.">
					<Spin spinning={loadingPriority} wrapperClassName="w-max">
						<InputNumber readOnly value={priority} />
					</Spin>
				</Form.Item>
				{[
					{
						name: 'icon_light',
						label: 'Gray Icon'
					},
					{
						name: 'icon_dark',
						label: 'Color Icon'
					}
				].map((icon) => (
					<Form.Item
						name={icon.name}
						key={icon.name}
						label={icon.label}
						rules={[
							{
								required: true,
								message: 'Please select icon file of lesson'
							}
						]}
						className="flex flex-row items-center"
					>
						<div>
							<SingleFileUploader
								type="lesson"
								onlyPNG
								afterUpload={(fileID) =>
									form.setFieldsValue({ [icon.name]: fileID })
								}
							/>
						</div>
					</Form.Item>
				))}
			</Form>
		</Modal>
	)
}
AddNewLessonModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired,
	onAdd: func.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired
}

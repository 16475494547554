import { Switch } from 'react-router-dom'

import { AuthLayout } from '../../components'
import { LoginPage, NotFoundPage } from '../../pages'

import GuestRoute from './guest-route'

export default function GuestRouter() {
	return (
		<AuthLayout>
			<Switch>
				<GuestRoute exact path="/auth/login">
					<LoginPage />
				</GuestRoute>
				<GuestRoute path="*">
					<NotFoundPage />
				</GuestRoute>
			</Switch>
		</AuthLayout>
	)
}

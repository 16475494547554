/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { message } from 'antd'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} EditTrophyPayload
 * @property {string} _id
 * @property {number} level1
 * @property {number} level2
 * @property {number} level3
 * @property {number} level4
 * @property {number} level5
 * @property {File} icon
 */

/**
 * @typedef {object} UpdateTrophyIconPayload
 * @property {string} trophy_id
 * @property {File} icon
 */

export default function useTrophies() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const { enableUpdateStatistics } = useUser()

	const fetchTrophies = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin_trophy')
				.then(({ data: trophies }) => {
					setData(trophies)
					resolve(trophies)
				})
				.catch(reject)
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchTrophy = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				axios
					.get(`/admin_trophy/${id}`)
					.then(({ data: trophy }) => resolve(trophy))
					.catch(reject)
			})
		},
		[]
	)

	const editTrophy = useCallback(
		/**
		 * @param {EditTrophyPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { _id: trophy_id } = payload
				message.loading({
					content: 'updating trophy data...',
					key: trophy_id
				})

				axios
					.put('/admin_trophy', payload)
					.then(({ data: updatedTrophyData }) => {
						message.success({
							content: 'trophy data updated',
							key: trophy_id
						})
						enableUpdateStatistics()
						fetchTrophies()
						resolve(updatedTrophyData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update trophy data',
							key: trophy_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchTrophies]
	)

	return {
		data,
		isLoading,
		fetchTrophies,
		fetchTrophy,
		editTrophy
	}
}

import { useReducer } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select } from 'antd'
import { object } from 'prop-types'

import { uniqueID } from '../../utils'

export default function MTPQuestion({ formInstance, initialValues }) {
	const forceUpdate = useReducer(() => ({}), {})[1]

	return (
		<Form name="MTP_question" form={formInstance} initialValues={initialValues}>
			<Form.Item
				name="questionText"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={5} />
			</Form.Item>
			<Form.List name="left_items">
				{(leftItems, { add, remove }, { errors }) => (
					<>
						<div className="w-full flex">
							<h3 className="w-1/2">Left Choices:</h3>
							<h3 className="w-1/2">Match with:</h3>
						</div>
						{leftItems.map((leftItem, index) => (
							<Form.Item
								required={false}
								key={leftItem.key}
								className="flex flex-col items-stretch w-full"
							>
								<div className="w-full flex flex-row flex-nowrap items-center">
									<Form.Item
										name={[leftItem.name, 'text']}
										fieldKey={[leftItem.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message:
													'Please input choice text or delete this field pair.'
											}
										]}
										noStyle
									>
										<Input
											className="mr-2"
											placeholder={`Choice ${index + 1}`}
										/>
									</Form.Item>
									<Form.Item
										name={[leftItem.name, 'match_id']}
										rules={[
											{
												required: true,
												message:
													'please select one of the right-items as pair for this field'
											}
										]}
										noStyle
									>
										<Select placeholder="blank" className="mr-2">
											{formInstance
												.getFieldValue('right_items')
												?.map((rightItem) =>
													rightItem.text ? (
														<Select.Option
															value={rightItem.id}
															key={rightItem.id}
														>
															{rightItem.text}
														</Select.Option>
													) : null
												)}
										</Select>
									</Form.Item>
									<button
										type="button"
										className="flex items-center focus:text-red-500 focus:outline-black"
									>
										<MinusCircleOutlined
											className="text-inherit"
											onClick={() => remove(leftItem.name)}
										/>
									</button>
								</div>
							</Form.Item>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() =>
									add({ id: uniqueID(), text: ``, match_id: undefined })
								}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								New Choice
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>

			<Form.List name="right_items">
				{(rightItems, { add, remove }) => (
					<>
						<h3>Right Choices:</h3>
						{rightItems.map((rightItem, index) => (
							<Form.Item
								required={false}
								key={rightItem.key}
								className="flex flex-col items-stretch w-full"
							>
								<div className="w-full flex flex-row flex-nowrap items-center">
									<Form.Item
										name={[rightItem.name, 'text']}
										fieldKey={[rightItem.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message:
													'Please input choice text or delete this field pair.'
											}
										]}
										noStyle
									>
										<Input
											className="mr-2"
											placeholder={`Choice ${index + 1}`}
											onChange={forceUpdate}
										/>
									</Form.Item>
									<button
										type="button"
										className="flex items-center focus:text-red-500 focus:outline-black"
									>
										<MinusCircleOutlined
											className="text-inherit"
											onClick={() => remove(rightItem.name)}
										/>
									</button>
								</div>
							</Form.Item>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => {
									add({ id: uniqueID(), text: '' })
									forceUpdate()
								}}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								New Choice
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	)
}

MTPQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}

/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import qs from 'query-string'

// import moment from 'moment'
import {
	DeleteBtn,
	getSearchFilterProps,
	getSelectFilterProps,
	QuestionModal
} from '../../components'
import { useQuestions, useQuestionTypes } from '../../hooks'
import { quizLevels } from '../../utils'

const ADD_MODAL = {
	title: 'New Quiz Question',
	ctaBtnText: 'ADD NEW QUIZ QUESTION'
}
const EDIT_MODAL = {
	title: 'Edit Quiz Question',
	ctaBtnText: 'Save'
}
export const AVAILABLE_QUIZ_TYPES = ['MC', 'MR']

export default function QuizQuestionsPage() {
	const [modal, setModal] = useState()

	const {
		data,
		isLoading,
		totalCount,
		fetchQuestions,
		addNewQuestion,
		deleteQuestion,
		bulkUploadQuestions,
		editQuestion
	} = useQuestions()

	const {
		fetchQuestionTypes,
		isLoading: questionTypesIsLoading
	} = useQuestionTypes()
	const [filteredTypes, setFilteredTypes] = useState([])

	const fileInputRef = useRef(null)
	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(() => setModalVisibility(false), [
		setModalVisibility
	])

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }
		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) {
				if (key === 'question') {
					Object.assign(params, { question_text: value[0] })
				} else if (key === 'type') {
					Object.assign(params, { question_type_id: value[0] })
				} else {
					Object.assign(params, { [key]: value[0] })
				}
			}
		})
		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}
		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchQuestions('admin_quiz', params)
	}

	const onChangeFile = (e) => {
		e.stopPropagation()
		e.preventDefault()
		const file = e.target.files[0]
		if (file) {
			bulkUploadQuestions(file, 'quizQuestions').then(() =>
				fetchQuestions('admin_quiz', queryParams)
			)
		}
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}
	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchQuestions('admin_quiz')
	}
	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	useEffect(() => {
		fetchQuestionTypes().then((QTs) => {
			setFilteredTypes(
				QTs.filter((el) => AVAILABLE_QUIZ_TYPES.includes(el.name))
			)
		})
	}, [fetchQuestionTypes])

	useEffect(() => {
		fetchQuestions('admin_quiz', queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchQuestions])

	useEffect(() => {
		if (queryParams.mode === 'edit_question') {
			setModal(EDIT_MODAL)
		} else if (queryParams.mode === 'add') setModal(ADD_MODAL)
	}, [queryParams.mode])

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold mr-auto">Quiz Questions List</h1>
				<Button
					disabled={resetFiltersDisabled}
					onClick={resetFilters}
					type="default"
					className="mr-2"
				>
					Reset All Filters
				</Button>
				<input
					type="file"
					ref={fileInputRef}
					className="hidden"
					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={onChangeFile}
				/>
				<Button
					type="primary"
					onClick={() => fileInputRef.current?.click()}
					icon={<UploadOutlined />}
					className="flex items-center mr-2"
				>
					BULK UPLOAD
				</Button>
				<Button
					type="primary"
					onClick={() => {
						setModalVisibility(true, { mode: 'add' })
					}}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				loading={isLoading}
				dataSource={data}
				className="mx-4 text-xs"
				rowClassName="text-xs"
				tableLayout="fixed"
				size="small"
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 239px)',
					x: 1024
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					title="Level"
					dataIndex={['level']}
					key="level"
					sorter
					sortOrder={getSortOrder('sort_level')}
					{...getSelectFilterProps('level', quizLevels, (record) => (
						<Select.Option value={record.id}>{record.name}</Select.Option>
					))}
					filteredValue={getFilterValue('level')}
					render={(value) =>
						quizLevels.find((level) => level.id === +value)?.name
					}
				/>
				<Table.Column
					title="Question"
					dataIndex={['details', 'questionText']}
					key="question"
					ellipsis
					{...getSearchFilterProps('questions')}
					filteredValue={getFilterValue('question_text')}
				/>
				<Table.Column
					title="Q-Type"
					dataIndex={['question_type', 'name']}
					key="type"
					{...getSelectFilterProps('question type', filteredTypes, (record) => (
						<Select.Option value={record._id}>{record.name}</Select.Option>
					))}
					filteredValue={getFilterValue('question_type_id')}
				/>
				<Table.Column
					title="Status"
					dataIndex="status"
					key="status"
					{...getSelectFilterProps(
						'status',
						[
							{
								_id: 'active',
								title: 'Active'
							},
							{
								_id: 'inactive',
								title: 'Inactive'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('status')}
				/>
				{/* <Table.Column
					title="Last Modified"
					dataIndex="last_modified"
					key="last_modified"
					render={(val) => moment(val).format('DD MMM, YYYY')}
				/> */}
				<Table.Column
					key="actions"
					title="Actions"
					className="text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'edit_question',
										record_id: record._id
									})
								}}
							/>
							{/* <DeleteBtn
								onDelete={() => deleteQuestion(record._id, 'admin_quiz')}
							/> */}
						</div>
					)}
				/>
			</Table>
			<QuestionModal
				questionCategory="admin_quiz"
				onClose={handleCloseModal}
				title={modal?.title}
				ctaBtnText={modal?.ctaBtnText}
				onAdd={(payload) => addNewQuestion(payload, 'admin_quiz')}
				onEdit={(payload) => editQuestion(payload, 'admin_quiz')}
				onDelete={deleteQuestion}
				questionTypes={filteredTypes}
				questionTypesIsLoading={questionTypesIsLoading}
				queryParams={queryParams}
			/>
		</>
	)
}

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EditOutlined } from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import { func, string } from 'prop-types'
import qs from 'query-string'

import { getSelectFilterProps, Icon, QuestionModal } from '../../components'
import {
	useLessons,
	useQuestions,
	useQuestionTypes,
	useReports,
	useSubjects
} from '../../hooks'
import EditLessonModal from '../lessons/edit-btn'
import { AVAILABLE_QUIZ_TYPES } from '../quiz-questions'
import { AVAILABLE_REVISION_TYPES } from '../revision-lessons/index'
import RevisionModal from '../revision-lessons/modal'
import ViewUserModal from '../users/view-user-modal'

// eslint-disable-next-line import/no-cycle
import ViewAndRespondModal from './modal'

export const modalModes = {
	editQuestion: 'edit_question',
	editLesson: 'edit_lesson',
	editRevision: 'edit_revision',
	viewUser: 'view_user'
}
const ResponseColumn = ({ val, onAction }) => {
	return (
		val || (
			<Button
				type="default"
				htmlType="submit"
				className="edit-btn px-2"
				onClick={onAction}
			>
				<Icon
					variant="reply"
					width={24}
					height={24}
					fill="currentColor"
					className="mr-2"
				/>
				Respond to user
			</Button>
		)
	)
}
ResponseColumn.propTypes = {
	val: string,
	onAction: func.isRequired
}
ResponseColumn.defaultProps = {
	val: ''
}

export default function ReportsPage() {
	const {
		isLoading,
		data,
		totalCount,
		fetchReports,
		respondToReport
	} = useReports()

	useEffect(() => {
		fetchReports()
	}, [fetchReports])

	const { editQuestion } = useQuestions()

	const { editLesson, deleteLesson } = useLessons()

	const {
		data: subjectsData,
		fetchSubjects,
		isLoading: subjectsIsLoading
	} = useSubjects()

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	const {
		data: questionTypes,
		fetchQuestionTypes,
		isLoading: questionTypesIsLoading
	} = useQuestionTypes()

	const [quizTypes, setQuizTypes] = useState([])
	const [revisionTypes, setRevisionTypes] = useState([])

	useEffect(() => {
		fetchQuestionTypes().then((QTs) => {
			const quizzes = []
			const revisions = []
			QTs.forEach((qt) => {
				if (AVAILABLE_QUIZ_TYPES.includes(qt.name)) quizzes.push(qt)
				if (AVAILABLE_REVISION_TYPES.includes(qt.name)) revisions.push(qt)
			})
			setQuizTypes(quizzes)
			setRevisionTypes(revisions)
		})
	}, [fetchQuestionTypes])

	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const [modalType, setModalType] = useState(null)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
				delete params.record_type
				setModalType(null)
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return (
			modal_is_open && Object.values(modalModes).includes(mode) && record_id
		)
	}, [queryParams])

	const handleCloseModal = useCallback(() => setModalVisibility(false), [
		setModalVisibility
	])

	const normalizeText = (str) =>
		str.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase())

	const handleTableChange = (pagination, filters) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }
		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) {
				Object.assign(params, { [key]: value[0] })
			}
		})
		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchReports(params)
	}

	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : undefined
	}

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold max-w-">Reports List</h1>
			</header>
			<Table
				className="mx-4"
				dataSource={data}
				tableLayout="fixed"
				size="small"
				loading={isLoading}
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 219px)',
					x: 768
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					dataIndex="description"
					title="Description"
					key="description"
				/>
				<Table.Column
					dataIndex="responded"
					title="Response"
					key="responded"
					className=""
					render={(_val, record) => (
						<ResponseColumn
							val={record.respond}
							onAction={() => {
								setModalVisibility(true, {
									mode: 'view_and_reply',
									record_id: record._id
								})
							}}
						/>
					)}
					{...getSelectFilterProps(
						'response status',
						[
							{
								_id: true,
								title: 'Responded'
							},
							{
								_id: false,
								title: 'Not-Responded'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					defaultFilteredValue={getFilterValue('responded')}
				/>
				<Table.Column
					title="References"
					key="references"
					className="text-right"
					render={(_val, record) => (
						<div className="flex flex-row items-center justify-end">
							<Button
								type="default"
								className="edit-btn px-2 py-1 mr-2"
								onClick={() => {
									setModalType('view_user')
									setModalVisibility(true, {
										mode: modalModes.viewUser,
										record_id: record.user_id
									})
								}}
							>
								<Icon
									variant="user"
									width={24}
									height={24}
									fill="currentColor"
									className="mr-2"
								/>
								{record.user.name}
							</Button>
							<Button
								type="default"
								className="edit-btn px-2 py-1"
								onClick={() => {
									const params = {
										record_id: record.record_id,
										record_type: record.type
									}
									switch (record.type) {
										case 'micro_lesson':
										case 'quiz':
											params.mode = modalModes.editQuestion
											setModalType('question')
											break
										case 'lesson':
											params.mode = modalModes.editLesson
											setModalType(record.type)
											break
										case 'revision':
											params.mode = modalModes.editRevision
											setModalType(record.type)
											break
										default:
											params.mode = 'edit'
											setModalType(null)
											break
									}
									setModalVisibility(true, params)
								}}
							>
								<EditOutlined className="mr-2" />
								{normalizeText(record.type)}
							</Button>
							{/* <Link
								className="edit-btn px-2 py-1"
								to={`/${(() => {
									switch (record.type) {
										case 'micro_lesson':
											return 'micro-lessons'
										case 'quiz':
											return 'quiz-questions'
										case 'revision':
											return 'revision-lessons'
										case 'lesson':
											return 'lessons'
										default:
											return ''
									}
								})()}?modal_is_open=true&mode=edit&record_id=${
									record.record_id
								}`}
							>
								<EditOutlined className="mr-2" />
								{normalizeText(record.type)}
							</Link> */}
						</div>
					)}
				/>
			</Table>
			<ViewAndRespondModal
				onClose={() => setModalVisibility(false)}
				onRequest={respondToReport}
				queryParams={queryParams}
				setModalType={setModalType}
				setModalVisibility={setModalVisibility}
			/>
			<QuestionModal
				questionCategory={
					queryParams?.record_type === 'quiz' ? 'admin_quiz' : 'admin_question'
				}
				onClose={handleCloseModal}
				onEdit={editQuestion}
				title="Edit Question"
				ctaBtnText="Save"
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
				questionTypes={
					queryParams?.record_type === 'quiz' ? quizTypes : questionTypes
				}
				questionTypesIsLoading={questionTypesIsLoading}
				queryParams={queryParams}
				isVisible={modalIsVisible && modalType === 'question'}
			/>
			<EditLessonModal
				onClose={handleCloseModal}
				onEdit={editLesson}
				onDelete={(id) => deleteLesson(id)}
				isVisible={modalIsVisible && modalType === 'lesson'}
				queryParams={queryParams}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
			/>
			<RevisionModal
				onClose={handleCloseModal}
				title="Edit Revision Lesson"
				ctaBtnText="Save"
				onEdit={(payload) => editQuestion(payload, 'admin_microrevision')}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
				questionTypes={revisionTypes}
				questionTypesIsLoading={questionTypesIsLoading}
				isVisible={modalIsVisible && modalType === 'revision'}
			/>
			<ViewUserModal
				onClose={handleCloseModal}
				queryParams={queryParams}
				isVisible={modalIsVisible && modalType === 'view_user'}
			/>
		</>
	)
}

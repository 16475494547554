import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { func, object, oneOf } from 'prop-types'

import { uniqueID } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

export default function EATorSTSQuestion({
	formInstance,
	type,
	initialValues,
	onChangeValues
}) {
	return (
		<Form
			name={`${type}_question`}
			form={formInstance}
			initialValues={initialValues}
			onValuesChange={onChangeValues}
		>
			{type === 'EAT' && (
				<Form.Item
					name="file"
					label="File"
					rules={[
						{
							required: true,
							message: 'Please upload a video/audio file for question'
						}
					]}
				>
					<div className="flex flex-col items-start justify-start w-max">
						<SingleFileUploader
							type="other"
							defaultFile={initialValues?.file}
							variant="FileList"
							getFileAddressInsteadOfID
							afterUpload={(fileAddress) =>
								formInstance.setFieldsValue({ file: fileAddress })
							}
							onlyMP3
							accept=".mp3"
							listType="text"
						/>
					</div>
				</Form.Item>
			)}
			<Form.List name="texts">
				{(fields, { add, remove }, { errors }) => (
					<>
						<h3 className="font-normal">Texts:</h3>
						{fields.map((field, index) => (
							<div
								className="flex flex-row flex-nowrap items-center"
								key={field.key}
							>
								<Form.Item
									required={false}
									className="flex-1 mr-2 border-b last:border-b-0 mb-4"
								>
									<Form.Item
										name={[field.name, 'text']}
										fieldKey={[field.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message: `this field is required`
											}
										]}
									>
										<Input placeholder={`Arabic Text ${index + 1}`} />
									</Form.Item>
									<Form.Item
										name={[field.name, 'translation']}
										fieldKey={[field.fieldKey, 'translation']}
										rules={[
											{
												required: true,
												message: `this field is required`
											}
										]}
									>
										<Input placeholder={`English Translation ${index + 1}`} />
									</Form.Item>
								</Form.Item>
								<button
									type="button"
									className="flex items-center focus:text-red-500 focus:outline-black"
								>
									<MinusCircleOutlined
										className="text-inherit"
										onClick={() => remove(field.name)}
									/>
								</button>
							</div>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() =>
									add({
										id: uniqueID(),
										text: '',
										translation: ''
									})
								}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								New Text
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
			{type === 'EAT' && (
				<Form.Item
					name="completeText"
					label="Transliteration"
					labelAlign="left"
					className="flex flex-col"
					rules={[
						{
							required: true,
							message: 'Please input a text for question'
						}
					]}
				>
					<Input.TextArea rows={5} />
				</Form.Item>
			)}
		</Form>
	)
}

EATorSTSQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	type: oneOf(['STS', 'EAT']).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	onChangeValues: func
}
EATorSTSQuestion.defaultProps = {
	onChangeValues: undefined
}

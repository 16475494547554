/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import { EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, message, Select, Table } from 'antd'
// import arrayMove from 'array-move'
import qs from 'query-string'

import {
	AddNewLessonModal,
	getSearchFilterProps,
	getSelectFilterProps
} from '../../components'
import { useLessons, useLevels, useSubjects } from '../../hooks'

import EditLessonModal from './edit-btn'

import './index.scss'

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export function LessonsPage() {
	const {
		data,
		isLoading,
		fetchLessons,
		addNewLesson,
		deleteLesson,
		editLesson,
		totalCount
	} = useLessons()

	const {
		data: subjectsData,
		isLoading: subjectsIsLoading,
		fetchSubjects
	} = useSubjects()

	const { data: levelsData, fetchAllLevels } = useLevels()

	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(() => setModalVisibility(false), [
		setModalVisibility
	])

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) {
				let paramKey
				switch (key) {
					case 'subject':
						paramKey = 'subject_id'
						break
					case 'level':
						paramKey = 'level_index'
						break
					default:
						paramKey = key
						break
				}
				Object.assign(params, { [paramKey]: value[0] })
			}
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}

		history.replace({
			search: qs.stringify(params, { skipNull: true, skipEmptyString: true })
		})
		fetchLessons(params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}
	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchLessons()
	}

	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	useEffect(() => {
		fetchAllLevels()
	}, [fetchAllLevels])

	useEffect(() => {
		fetchLessons(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchLessons])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const item = data[oldIndex]
			const min = Math.min(oldIndex, newIndex)
			const max = Math.max(oldIndex, newIndex)

			const filteredData = data
				.reduce((res, cur, index) => {
					res.push({ ...cur, index })
					return res
				}, [])
				.filter(
					(el, index) =>
						index >= min && index <= max && el?.level?._id === item?.level?._id
				)
			if (filteredData.length > 1) {
				let priority
				if (oldIndex > newIndex) {
					// moved up
					const target = filteredData[0]
					priority = target?.priority
				} else {
					// moved down
					const target = filteredData[filteredData.length - 1]
					priority = target?.priority
				}
				editLesson({
					_id: item._id,
					level: item.level._id,
					priority
				})
				// const newData = arrayMove([].concat(dataSource), oldIndex, index).filter(el => !!el)
			} else {
				message.error('You can only reorder lessons within the same level')
			}
		}
	}

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => document.querySelector('.ant-table-tbody')}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Lessons List</h1>
				<Button
					onClick={resetFilters}
					disabled={resetFiltersDisabled}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				scroll={{
					y: 'calc(100vh - 242px)',
					x: 640
				}}
				tableLayout="fixed"
				rowKey={(record) => record._id}
				rowClassName="lesson-row"
				size="small"
				className="mx-4"
				loading={isLoading}
				dataSource={data}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			>
				<Table.Column
					dataIndex="sort"
					key="sort"
					className="drag-handle-col text-center"
					width={48}
					title={
						<Button
							type={reordering ? 'primary' : 'default'}
							icon={<MenuOutlined />}
							className="w-full h-full flex items-center justify-center"
							onClick={() => setReordering((prev) => !prev)}
						/>
					}
					render={reordering ? () => <DragHandle /> : null}
				/>
				<Table.Column
					dataIndex={['level', 'subject', 'title']}
					title="Subject"
					key="subject"
					className="subject-title-col"
					width={178}
					filteredValue={getFilterValue('subject_id')}
					{...getSelectFilterProps('subject', subjectsData, (record) => (
						<Select.Option value={record._id} key={record._id}>
							{record.title}
						</Select.Option>
					))}
				/>
				<Table.Column
					dataIndex={['level', 'index']}
					title="Level"
					key="level"
					className="lvl-index-col text-center"
					width={110}
					sorter
					sortOrder={getSortOrder('sort_level')}
					{...getSelectFilterProps('level', levelsData, (record) => (
						<Select.Option value={record.index}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('level_index')}
				/>
				<Table.Column
					dataIndex="priority"
					title="Lesson NO."
					key="priority"
					className="lesson-number-col highlighted-number text-center"
					width={112}
					sorter
					sortOrder={getSortOrder('sort_priority')}
				/>
				<Table.Column
					dataIndex="title"
					title="Title"
					key="title"
					className="lesson-title-col"
					sorter
					sortOrder={getSortOrder('sort_title')}
					{...getSearchFilterProps('title')}
					filteredValue={getFilterValue('title')}
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="actions-col text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit_lesson',
										record_id: record._id
									})
								}
							/>
						</div>
					)}
				/>
			</Table>
			<EditLessonModal
				onClose={handleCloseModal}
				onEdit={editLesson}
				onDelete={(id) => deleteLesson(id)}
				queryParams={queryParams}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
			/>
			<AddNewLessonModal
				visible={
					!!(queryParams?.modal_is_open && queryParams.mode === 'add') || false
				}
				setVisible={setModalVisibility}
				onAdd={addNewLesson}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
			/>
		</>
	)
}

export default LessonsPage

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddLessonPayload
 * @property {string} title
 * @property {string} level
 * @property {string} icon_light
 * @property {string} icon_dark
 */

/**
 * @typedef {object} EditLessonPayload
 * @property {string} _id
 * @property {string} title
 * @property {string} level
 * @property {string} icon_light
 * @property {string} icon_dark
 */

/**
 * @typedef {object} UpdateLessonIconsPayload
 * @property {string} lesson_id
 * @property {string} icon_light
 * @property {string} icon_dark
 */
function useLessons() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchLessons = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin_lesson?${qs.stringify(queryParams, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: { data: lessons, total_count } }) => {
						setTotalCount(total_count)
						setData(lessons)
						resolve(lessons)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchLesson = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) reject(new Error('Lesson ID not provided'))
				else {
					setLoading(true)
					axios
						.get(`/admin_lesson/${id}`)
						.then(({ data: lesson }) => resolve(lesson))
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const updateLessonIcons = useCallback(
		/**
		 * @param {UpdateLessonIconsPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { lesson_id } = payload
				message.loading({ content: 'updating lesson icons...', key: lesson_id })
				axios
					.post('/admin_lesson/icon_ids', payload)
					.then(({ data: lessonNewIcons }) => {
						message.success({ content: 'lesson icons updated', key: lesson_id })
						resolve(lessonNewIcons)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update lesson icons',
							key: lesson_id
						})
						reject(err)
					})
			})
		},
		[]
	)

	const addNewLesson = useCallback(
		/**
		 * @param {AddLessonPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon_dark, icon_light, ...restPayload } = payload
				message.loading({
					content: 'adding new lesson...',
					key: payload.title
				})
				axios
					.post('/admin_lesson', restPayload)
					.then(({ data: newLesson }) => {
						message.success({
							content: 'new lesson added',
							key: payload.title
						})
						enableUpdateStatistics()
						const { _id: lesson_id } = newLesson

						updateLessonIcons({ lesson_id, icon_light, icon_dark })
							.then((lessonIconsData) => {
								Object.assign(newLesson, lessonIconsData)
							})
							.finally(() => {
								fetchLessons(qs.parse(search))
								resolve(newLesson)
							})
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new lesson',
							key: payload.title
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLessons, search, updateLessonIcons]
	)

	const deleteLesson = useCallback(
		/**
		 * @param {string} id - lesson _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting lesson...', key: id })
				axios
					.delete(`/admin_lesson/${id}`)
					.then(() => {
						message.success({
							content: 'lesson deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchLessons(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'deleting lesson operation failed',
							key: id
						})
						reject(err)
					})
					.finally(() => fetchLessons(qs.parse(search)))
			})
		},
		[enableUpdateStatistics, fetchLessons, search]
	)

	const editLesson = useCallback(
		/**
		 * @param {EditLessonPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon_dark, icon_light, ...restPayload } = payload || {}
				const { _id: lesson_id } = restPayload
				message.loading({ content: 'updating lesson data...', key: lesson_id })

				axios
					.put('/admin_lesson', restPayload)
					.then(({ data: editedLesson }) => {
						message.success({ content: 'lesson data updated', key: lesson_id })
						enableUpdateStatistics()
						if (icon_light || icon_dark) {
							const iconsPayload = { lesson_id }
							if (icon_light) Object.assign(iconsPayload, { icon_light })
							if (icon_dark) Object.assign(iconsPayload, { icon_dark })

							updateLessonIcons(iconsPayload)
								.then((updatedLessonIcons) => {
									Object.assign(editedLesson, updatedLessonIcons)
								})
								.finally(() => {
									fetchLessons(qs.parse(search))
									resolve(editedLesson)
								})
						} else {
							fetchLessons(qs.parse(search))
							resolve(editedLesson)
						}
					})
					.catch((err) => {
						message.error({
							content: 'failed to update lesson',
							key: lesson_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLessons, search, updateLessonIcons]
	)

	return {
		data,
		isLoading,
		totalCount,
		fetchLessons,
		fetchLesson,
		addNewLesson,
		deleteLesson,
		editLesson
	}
}
export default useLessons

import { MainProvider } from './providers/main-provider'
import { MainRouter } from './routers'

import './styles/index.scss'
import 'antd/dist/antd.css'

export function App() {
	return (
		<MainProvider>
			<MainRouter />
		</MainProvider>
	)
}

export default App

import { useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Spin, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import cn from 'classnames'
import { string } from 'prop-types'

import { useUser } from '../../store/hooks'

export function UploadAvatar({ className }) {
	const user = useUser()
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setLoading] = useState(false)

	const customRequest = ({ file }) => {
		setLoading(true)
		user.uploadAvatar({ file }).finally(() => setLoading(false))
	}

	return (
		<div
			className={cn('flex items-center justify-center', className, {
				'pointer-events-none cursor-wait': isLoading
			})}
		>
			<Spin spinning={isLoading}>
				<ImgCrop>
					<Upload.Dragger
						className="rounded-full overflow-hidden flex items-center justify-center w-24 h-24"
						listType="picture-card"
						accept="image/png, image/jpeg"
						maxCount={1}
						multiple={false}
						showUploadList={false}
						customRequest={customRequest}
					>
						{user.avatar ? (
							<img src={user.avatar} alt="avatar" className="w-full" />
						) : (
							<div className="flex flex-col items-center justify-center">
								{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
								<span className="mt-2">Upload</span>
							</div>
						)}
					</Upload.Dragger>
				</ImgCrop>
			</Spin>
		</div>
	)
}
UploadAvatar.propTypes = {
	className: string
}
UploadAvatar.defaultProps = {
	className: undefined
}
export default UploadAvatar

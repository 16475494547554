/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import moment from 'moment'

import { axios, md5, UPLOADS_SERVER_URL } from '../../utils'
import userSlice from '../reducers/user'

const { actions } = userSlice

const useUser = () => {
	const user = useSelector((state) => state.user, shallowEqual)
	const dispatchUser = useDispatch()

	const updateState = useCallback(
		(newState) => {
			dispatchUser(actions.updateState(newState))
		},
		[dispatchUser]
	)

	const getEmailHash = useCallback((email = '') => {
		return md5(email.trim().toLowerCase())
	}, [])

	const updateAvatar = useCallback(() => {
		let avatar
		if (user.image_path) {
			avatar = `${UPLOADS_SERVER_URL}/uploads/${user.image_path}`
		} else {
			avatar = `https://www.gravatar.com/avatar/${getEmailHash(
				user.email
			)}?default=mp`
		}
		updateState({
			avatar
		})
	}, [getEmailHash, updateState, user.email, user.image_path])

	const login = ({ email, password }) => {
		return new Promise((resolve, reject) => {
			axios
				.post('/admin_auth/login', { email, password })
				.then((res) => {
					updateState({ ...res.data.user })
					// eslint-disable-next-line camelcase
					const { access_token, refresh_token } = res.data

					localStorage.setItem('access_token', access_token)
					localStorage.setItem('refresh_token', refresh_token)

					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const assignNewAdmin = ({ name, email, password }) => {
		return new Promise((resolve, reject) => {
			axios
				.post('/admin_auth/register', { name, email, password })
				.then((res) => {
					if (res.status === 200)
						message.success('New admin added successfully')
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const fetchProfile = useCallback(() => {
		return new Promise((resolve, reject) => {
			axios
				.get('/admin_auth/profile')
				.then((res) => {
					updateState(res.data)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}, [updateState])

	const updateProfile = ({ name, email }) => {
		return new Promise((resolve, reject) => {
			axios
				.put('/admin_auth/profile', { name, email })
				.then((res) => {
					if (res.status && res.status === 200)
						message.success('Profile edited successfully')
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const uploadAvatar = ({ file }) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData()
			formData.append('image_path', file)
			axios
				.post('/admin_auth/picture', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(({ data, status }) => {
					if (status === 200) {
						// eslint-disable-next-line camelcase
						const { image_path } = data || {}
						updateState({
							image_path: image_path.replace(/^(\/uploads\/)/, '')
						})
						message.success('Avatar picture edited successfully')
					}
					resolve(data)
				})
				.catch((err) => {
					reject(err)
				})
			return {
				abort() {
					message.error('upload progress is aborted.')
				}
			}
		})
	}
	const disableUpdateStatistics = () => {
		updateState({ shouldUpdateStatistics: false })
	}
	const enableUpdateStatistics = useCallback(() => {
		updateState({ shouldUpdateStatistics: true })
	}, [updateState])

	const fetchStatistics = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (user.shouldUpdateStatistics && user._id) {
				axios
					.get('/dashboard')
					.then(({ data: res }) => {
						const { admins, ...rest } = res
						const activities = admins
							.reduce((finalRes, cur) => {
								const { activities: adminActivities, ...adminInfo } = cur
								const temp = adminActivities?.map((activity) => {
									let type = null
									const title =
										activity.title.charAt(0).toUpperCase() +
										activity.title.slice(1).toLowerCase()

									if (!title.includes('Deleted')) {
										if (title.includes('question type')) type = 'question-types'
										else if (title.includes('question')) type = 'micro-lessons'
										else if (title.includes('quiz')) type = 'quiz-questions'
										else if (title.includes('revision'))
											type = 'revision-lessons'
										else if (title.includes('subject')) type = 'subjects'
										// else if (title.includes('level')) type = 'levels'
										else if (title.includes('lesson')) type = 'lessons'
										else if (title.includes('trophy')) type = 'trophies'
										else if (title.includes('avatar')) type = 'avatars'
										else if (title.includes('gift')) type = 'gifts'
										else if (title.includes('bug report')) type = 'bug-reports'
									}

									return {
										...activity,
										...adminInfo,
										type,
										title
									}
								})
								if (temp) finalRes.push(...temp)
								return finalRes
							}, [])
							.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
							.slice(0, 5)

						Object.assign(rest, { activities })
						updateState({ statistics: rest, shouldUpdateStatistics: false })
						resolve()
					})
					.catch(reject)
			}
		})
	}, [updateState, user._id, user.shouldUpdateStatistics])

	return {
		...user,
		updateState,
		login,
		assignNewAdmin,
		updateProfile,
		fetchProfile,
		uploadAvatar,
		updateAvatar,
		disableUpdateStatistics,
		enableUpdateStatistics,
		fetchStatistics
	}
}
export default useUser

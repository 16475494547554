import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { object } from 'prop-types'

import { uniqueID } from '../../utils'

export default function ARQuestion({ formInstance, initialValues }) {
	return (
		<Form name="AR_question" form={formInstance} initialValues={initialValues}>
			<Form.Item
				name="questionText"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={5} />
			</Form.Item>
			<Form.List name="choices">
				{(fields, { add, remove }, { errors }) => (
					<>
						{fields.map((field, index) => (
							<Form.Item
								label={index === 0 ? 'Choices' : ''}
								required={false}
								key={field.key}
								className="flex flex-col items-stretch w-full"
								labelAlign="left"
							>
								<div className="w-full flex flex-row flex-nowrap items-center">
									<Form.Item
										name={[field.name, 'text']}
										fieldKey={[field.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message: `Please input choice text${
													fields.length > 1 ? ' or delete this field' : ''
												}.`
											}
										]}
										noStyle
									>
										<Input
											className="mr-2"
											placeholder={`Choice ${index + 1}`}
										/>
									</Form.Item>
									{fields.length > 1 && (
										<button
											type="button"
											className="flex items-center focus:text-red-500 focus:outline-black"
										>
											<MinusCircleOutlined
												className="text-inherit"
												onClick={() => remove(field.name)}
											/>
										</button>
									)}
								</div>
							</Form.Item>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => add({ id: uniqueID(), text: `` })}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								Add choice
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	)
}

ARQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}

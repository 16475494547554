/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import qs from 'query-string'

import { axios } from '../utils'

/**
 * @typedef {object} DonationsStatisticsQueryParams
 * @property {string} gift_id
 * @property {string} user_id
 * @property {string} child_id
 */

export default function useGiftsDonations() {
	const [data, setData] = useState([])
	const [totalCount, setTotalCount] = useState(0)
	const [isLoading, setIsLoading] = useState(true)

	const fetchGiftsDonations = useCallback(
		/**
		 * @param {DonationsStatisticsQueryParams} queryParams
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setIsLoading(true)
				const endpoint = `/	admin_gift/report?${qs.stringify(queryParams, {
					skipNull: true,
					skipEmptyString: true
				})}`

				axios
					.get(endpoint)
					.then(({ data: donations }) => {
						const { data: donationsData, total_count } = donations
						setTotalCount(total_count)
						setData(donationsData)
						resolve(donationsData)
					})
					.catch(reject)
					.finally(() => setIsLoading(false))
			})
		},
		[]
	)
	const bulkEditGiftDonations = useCallback(
		/**
		 * @param {string[]} selections
		 * @param {boolean} donation_status
		 */
		({ selections, donation_status }) => {
			return new Promise((resolve, reject) => {
				const payload = {
					gift_reports: selections.map((selection) => ({
						_id: selection,
						donation_status
					}))
				}
				axios
					.put('/admin_gift/report/bulk', payload)
					.then(({ data: res }) => resolve(res))
					.catch(reject)
			})
		},
		[]
	)

	return {
		data,
		totalCount,
		isLoading,
		fetchGiftsDonations,
		bulkEditGiftDonations
	}
}

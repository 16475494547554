/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import { EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { useGifts } from '../../hooks'

import GiftModal from './modal'

const ADD_MODAL = {
	title: 'New Gift',
	ctaBtnText: 'ADD NEW GIFT'
}
const EDIT_MODAL = {
	title: 'Edit Gift',
	ctaBtnText: 'Save'
}

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export default function ShopItemsPage() {
	const {
		data,
		totalCount,
		isLoading,
		fetchGifts,
		addNewGift,
		deleteGift,
		editGift
	} = useGifts()

	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const [modal, setModal] = useState()

	useEffect(() => {
		if (queryParams.mode === 'edit') {
			setModal(EDIT_MODAL)
		} else if (queryParams.mode === 'add') setModal(ADD_MODAL)
	}, [queryParams.mode])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(() => setModalVisibility(false), [
		setModalVisibility
	])

	const handleTableChange = (pagination) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchGifts(params)
	}

	useEffect(() => {
		fetchGifts(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchGifts])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			editGift({
				_id: data[oldIndex]._id,
				order: data[newIndex].order
			})
		}
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => document.querySelector('.ant-table-tbody')}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold">Gifts List</h1>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				className="mx-4"
				loading={isLoading}
				dataSource={data}
				size="small"
				tableLayout="fixed"
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 259px)',
					x: 600
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			>
				<Table.Column
					dataIndex="sort"
					key="sort"
					width={48}
					className="drag-handle-col text-center"
					title={
						<Button
							type={reordering ? 'primary' : 'default'}
							icon={<MenuOutlined />}
							className="w-full h-full flex items-center justify-center"
							onClick={() => setReordering((prev) => !prev)}
						/>
					}
					render={reordering ? () => <DragHandle /> : null}
				/>
				<Table.Column
					dataIndex="title"
					title="Title"
					key="title"
					// ellipsis
					width={212}
				/>
				<Table.Column
					dataIndex="description"
					title="Description"
					key="description"
					ellipsis
				/>
				<Table.Column
					dataIndex="coins"
					title="Coins"
					key="coins"
					className="text-center"
					width={96}
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="edit-btn mr-2"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}
							/>
							{/* <DeleteBtn onDelete={() => deleteGift(record._id)} /> */}
						</div>
					)}
				/>
			</Table>
			<GiftModal
				questionCategory="admin_question"
				onClose={handleCloseModal}
				title={modal?.title}
				ctaBtnText={modal?.ctaBtnText}
				onAdd={addNewGift}
				onEdit={editGift}
				onDelete={deleteGift}
			/>
		</>
	)
}

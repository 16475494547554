/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { message } from 'antd'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddSubjectPayload
 * @property {string} title
 * @property {string} description
 * @property {string} color
 * @property {string} icon_light
 * @property {string} icon_dark
 */

/**
 * @typedef {object} EditSubjectPayload
 * @property {string} _id
 * @property {string} title
 * @property {string} description
 * @property {string} color
 * @property {string} icon_light
 * @property {string} icon_dark
 */

/**
 * @typedef {object} UpdateSubjectIconsPayload
 * @property {string} subject_id
 * @property {string} icon_light
 * @property {string} icon_dark
 */
function useSubjects() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const { enableUpdateStatistics } = useUser()
	const fetchSubjects = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin_subject?size=100')
				.then(({ data: subjects }) => {
					setData(subjects)
					resolve(subjects)
				})
				.catch((err) => {
					reject(err)
				})
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchSubject = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error('subject ID not provided'))
				} else {
					setLoading(true)

					axios
						.get(`/admin_subject/${id}`)
						.then(({ data: subject }) => resolve(subject))
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const updateSubjectIcons = useCallback(
		/**
		 * @param {UpdateSubjectIconsPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { subject_id } = payload
				message.loading({
					content: 'updating subject icons...',
					key: subject_id
				})
				axios
					.post('/admin_subject/icon_ids', payload)
					.then(({ data: subjectNewIcons }) => {
						message.success({
							content: 'subject icons updated',
							key: subject_id
						})
						resolve(subjectNewIcons)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update subject icons',
							key: subject_id
						})
						reject(err)
					})
			})
		},
		[]
	)

	const addNewSubject = useCallback(
		/**
		 * @param {AddSubjectPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon_dark, icon_light, ...restPayload } = payload
				message.loading({
					content: 'adding new subject...',
					key: payload.title
				})
				axios
					.post('/admin_subject', restPayload)
					.then(({ data: newSubject }) => {
						message.success({
							content: 'new subject added',
							key: payload.title
						})
						enableUpdateStatistics()
						const { _id: subject_id } = newSubject

						updateSubjectIcons({ subject_id, icon_light, icon_dark })
							.then((subjectIconsData) => {
								Object.assign(newSubject, subjectIconsData)
							})
							.finally(() => {
								fetchSubjects()
								resolve(newSubject)
							})
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new subject',
							key: payload.title
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects, updateSubjectIcons]
	)

	const deleteSubject = useCallback(
		/**
		 * @param {string} id - subject _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting subject...', key: id })
				axios
					.delete(`/admin_subject/${id}`)
					.then(() => {
						message.success({
							content: 'subject deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchSubjects()
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'deleting subject operation failed',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects]
	)

	const editSubject = useCallback(
		/**
		 * @param {EditSubjectPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon_dark, icon_light, ...restPayload } = payload || {}
				const { _id: subject_id } = restPayload
				message.loading({
					content: 'updating subject data...',
					key: subject_id
				})

				axios
					.put('/admin_subject', restPayload)
					.then(({ data: editedSubject }) => {
						message.success({ content: 'subject edited', key: subject_id })
						enableUpdateStatistics()
						if (icon_light || icon_dark) {
							const iconsPayload = { subject_id }
							if (icon_light) Object.assign(iconsPayload, { icon_light })
							if (icon_dark) Object.assign(iconsPayload, { icon_dark })

							updateSubjectIcons(iconsPayload)
								.then((updatedSubjectIcons) => {
									Object.assign(editedSubject, updatedSubjectIcons)
								})
								.finally(() => {
									fetchSubjects()
									resolve(editedSubject)
								})
						} else {
							fetchSubjects()
							resolve(editedSubject)
						}
					})
					.catch((err) => {
						message.error({
							content: 'failed to update lesson',
							key: subject_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects, updateSubjectIcons]
	)

	return {
		data,
		isLoading,
		fetchSubjects,
		fetchSubject,
		addNewSubject,
		deleteSubject,
		editSubject
	}
}
export default useSubjects

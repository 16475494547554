import { useState } from 'react'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'

import { useUser } from '../../store/hooks'
import { logoutConfirm } from '../../utils'
import { ProfileModal } from '../profile-modal'

export function Header() {
	const user = useUser()

	const [profModalIsVisible, setProfModalVisible] = useState(false)
	const openProfModal = () => setProfModalVisible(true)

	return (
		<>
			<header className="w-full h-12 px-4 py-2 flex flex-row justify-between items-center">
				<div className="right-section ml-auto flex flex-row items-center justify-end">
					<Dropdown
						trigger={['click']}
						placement="bottomRight"
						overlay={
							<Menu>
								<Menu.Item
									key="profile"
									onClick={openProfModal}
									icon={<UserOutlined />}
									className="flex items-center h-10 text-lg"
								>
									Profile
								</Menu.Item>
								<Menu.Item
									key="logout"
									icon={<LogoutOutlined />}
									className="flex items-center h-10 text-lg"
									danger
									onClick={logoutConfirm}
								>
									Logout
								</Menu.Item>
							</Menu>
						}
					>
						<div className="flex flex-row items-center justify-center ml-3 cursor-pointer">
							{user.name ? (
								<span className="leading-none uppercase">{user.name}</span>
							) : (
								<span className="animate-pulse space-x-4 space-y-4 h-6 w-24 bg-gray-300 rounded-sm" />
							)}
							<img
								src={user.avatar}
								className="w-9 h-9 ml-2 rounded-full"
								alt="profile"
							/>
						</div>
					</Dropdown>
				</div>
			</header>
			<ProfileModal
				visible={profModalIsVisible}
				setVisible={setProfModalVisible}
			/>
		</>
	)
}

export default Header

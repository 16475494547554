import { Form, Input } from 'antd'
import { object, oneOf } from 'prop-types'

import SingleFileUploader from '../single-file-uploader'

export default function AASQuestion({ formInstance, initialValues, type }) {
	return (
		<Form name="AAS_question" form={formInstance} initialValues={initialValues}>
			{type === 'AAS' && (
				<Form.Item
					name="questionText"
					label="Title"
					labelAlign="left"
					className="flex flex-col"
					rules={[
						{
							required: true,
							message: 'Please input a text for question'
						}
					]}
				>
					<Input />
				</Form.Item>
			)}
			<Form.Item
				name="file"
				label="File"
				rules={[
					{
						required: true,
						message: {
							AS: 'Please upload an audio file',
							AAS: 'Please upload a mp4/png file'
						}[type]
					}
				]}
			>
				<div className="flex flex-col items-start justify-start w-max">
					<SingleFileUploader
						type="other"
						variant="FileList"
						defaultFile={initialValues?.file}
						getFileAddressInsteadOfID
						afterUpload={(fileAddress) =>
							formInstance.setFieldsValue({ file: fileAddress })
						}
						accept={
							{
								AS: '.mp3',
								AAS: '.mp4, .png'
							}[type]
						}
						onlyMP3={type === 'AS'}
						onlyMP4={type === 'AAS'}
						onlyPNG={type === 'AAS'}
						listType="text"
					/>
				</div>
			</Form.Item>
		</Form>
	)
}

AASQuestion.propTypes = {
	type: oneOf(['AS', 'AAS']).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}

import { useCallback } from 'react'

import { axios } from '../utils'

/**
 * @typedef {object} UploadFilePayload
 * @property {string} type
 * @property {File} file
 * @property {func} onProgress
 */

export default function useFiles() {
	/**
	 * @param {UploadFilePayload} payload
	 */
	const uploadFile = (payload) => {
		return new Promise((resolve, reject) => {
			const { type, file, onProgress } = payload || {}
			const fd = new FormData()
			fd.append('type', type)
			fd.append('file', file)
			axios
				.post('/admin_file', fd, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: (e) => {
						const percent = (e.loaded / e.total) * 100
						onProgress(percent)
					}
				})
				.then(({ data: { id: fileID } }) => resolve(fileID))
				.catch((err) => {
					reject(err.message)
				})
		})
	}

	const getFileAddressByID = useCallback(
		/**
		 * @param {string} id
		 * @returns {Promise<string>} file path
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (id) {
					axios
						.get(`/file_address/${id}`)
						.then(({ data }) => resolve(data))
						.catch(reject)
				} else {
					reject(new Error('File ID not provided'))
				}
			})
		},
		[]
	)

	/**
	 * @param {string} id - file _id
	 */
	const deleteFile = (id) => {
		return axios.delete(`/admin_file/${id}`)
	}

	return {
		uploadFile,
		deleteFile,
		getFileAddressByID
	}
}

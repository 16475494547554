import { Link } from 'react-router-dom'
import moment from 'moment'
import { shape, string } from 'prop-types'

import { Icon } from '../icon'

export function RecentActivityItem({ item }) {
	const getMode = (type) => {
		switch (type) {
			case 'lessons':
				return 'edit_lesson'
			case 'micro-lessons':
				return 'edit_question'
			case 'quiz-questions':
				return 'edit_question'
			case 'revisions':
				return 'edit_revision'
			case 'bug-reports':
				return 'view_and_reply'
			default:
				return 'edit'
		}
	}
	return (
		<div className="w-full p-3 my-2 bg-white flex flex-col items-start justify-center shadow-md rounded-md overflow-hidden">
			<span className="text-gray-500 text-xs">
				{moment(item?.date).format('DD-MM-YYYY, HH:mm')}
			</span>
			<p className="text-black text-sm m-0 my-1 flex items-center justify-start flex-wrap">
				{item?.title}
				{item.type && (
					<Link
						to={`/${item.type}?modal_is_open=true&mode=${getMode(
							item.type
						)}&record_id=${item.id}`}
					>
						<Icon
							variant="redirect"
							width={16}
							height={16}
							className="mx-2 cursor-pointer fill-current transition-colors duration-200"
						/>
					</Link>
				)}
			</p>
			<p className="text-black text-xs m-0">by {item?.name}</p>
		</div>
	)
}

RecentActivityItem.propTypes = {
	item: shape({
		title: string,
		date: string
	}).isRequired
}
export default RecentActivityItem

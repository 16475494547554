import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import rootReducer from './reducers'

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== 'production'
})

export default store

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import cn from 'classnames'
import { object, oneOf } from 'prop-types'

import { uniqueID } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

export default function MASQuestion({ formInstance, type, initialValues }) {
	return (
		<Form
			name={`${type}_question`}
			form={formInstance}
			initialValues={initialValues}
		>
			<Form.List
				name="slides"
				rules={[
					{
						validator: async (_, slides) => {
							if (!slides || slides.length < 1) {
								return Promise.reject(
									new Error('Please add at least one slide')
								)
							}

							return Promise.resolve()
						}
					}
				]}
			>
				{(fields, { add, remove }, { errors }) => (
					<>
						<div className="flex flex-row items-center justify-between">
							<h3 className="font-bold mb-0">Slides:</h3>
							{/* <h3 className="font-normal ml-auto mr-12">File:</h3> */}
						</div>
						<div>
							{fields.map((field, index) => (
								<Form.Item
									required={false}
									key={field.key}
									className="border-b-2 last:border-b-0 py-4 mb-0"
									label={`Slide ${index + 1}`}
								>
									<div className="w-full flex flex-row flex-nowrap items-center">
										<Form.Item
											name={[field.name, 'questionText']}
											fieldKey={[field.fieldKey, 'questionText']}
											rules={[
												{
													required: true,
													message: 'Please input slide text'
												}
											]}
											noStyle
											className="flex flex-col"
										>
											{
												{
													MIS: (
														<Input.TextArea
															placeholder="Question or text"
															className="mr-4 min-w-96"
															rows={3}
														/>
													),
													MAS: (
														<Input
															placeholder="Title"
															className="mr-4 min-w-96"
														/>
													)
												}[type]
											}
										</Form.Item>

										<Form.Item
											name={[field.name, 'file']}
											fieldKey={[field.fieldKey, 'file']}
											className="m-0 mr-2"
											rules={[
												type === 'MAS' && {
													required: true,
													message: 'Please upload a file for this slide'
												}
											]}
										>
											<div className="flex flex-col items-start justify-start max-w-xs">
												<SingleFileUploader
													type="other"
													accept={`.png, .mp4${type === 'MIS' ? ', .mp3' : ''}`}
													onlyMP3={type === 'MIS'}
													onlyMP4
													onlyPNG
													listType="text"
													variant="FileList"
													defaultFile={
														formInstance.getFieldValue('slides')[field.name]
															?.file
													}
													getFileAddressInsteadOfID
													afterUpload={(fileAddress) =>
														formInstance.setFieldsValue({
															slides: formInstance
																.getFieldValue('slides')
																.map((slide, i) => {
																	if (i === field.name) {
																		return {
																			...slide,
																			file: fileAddress
																		}
																	}
																	return slide
																})
														})
													}
												/>
											</div>
										</Form.Item>
										<button
											type="button"
											className={cn(
												'flex items-center focus:text-red-500 focus:outline-black self-start',
												{ 'mt-2.5': type === 'MAS', 'mt-8': type === 'MIS' }
											)}
										>
											<MinusCircleOutlined
												className="text-inherit"
												onClick={() => remove(field.name)}
											/>
										</button>
									</div>
								</Form.Item>
							))}
						</div>
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => {
									const newSlide = {
										id: uniqueID(),
										file: null
									}
									if (type === 'MIS') Object.assign(newSlide, { text: `` })
									add(newSlide)
								}}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								Add slide
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	)
}

MASQuestion.propTypes = {
	type: oneOf(['MAS', 'MIS']).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}

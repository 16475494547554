import { useEffect } from 'react'
import { Switch } from 'react-router-dom'

import { MainLayout } from '../../components'
import {
	AvatarsPage,
	GiftDonationsPage,
	GiftsPage,
	HomePage,
	LessonsPage,
	LevelsPage,
	MicroLessonsPage,
	NotFoundPage,
	QuestionTypesPage,
	QuizQuestionsPage,
	ReportsPage,
	RevisionLessonsPage,
	SubjectsPage,
	TrophiesPage,
	UsersPage
} from '../../pages'
import { useUser } from '../../store/hooks'

import UserRoute from './user-route'

export default function UserRouter() {
	const { fetchProfile, updateAvatar, fetchStatistics } = useUser()

	useEffect(() => {
		fetchProfile()
	}, [fetchProfile])

	useEffect(() => {
		updateAvatar()
	}, [updateAvatar])

	useEffect(() => {
		fetchStatistics()
	}, [fetchStatistics])

	return (
		<MainLayout>
			<Switch>
				<UserRoute exact path="/">
					<HomePage />
				</UserRoute>
				<UserRoute path="/levels">
					<LevelsPage />
				</UserRoute>
				<UserRoute path="/lessons">
					<LessonsPage />
				</UserRoute>
				<UserRoute path="/subjects">
					<SubjectsPage />
				</UserRoute>
				<UserRoute path="/question-types">
					<QuestionTypesPage />
				</UserRoute>
				<UserRoute path="/avatars">
					<AvatarsPage />
				</UserRoute>
				<UserRoute path="/users">
					<UsersPage />
				</UserRoute>
				<UserRoute path="/micro-lessons">
					<MicroLessonsPage />
				</UserRoute>
				<UserRoute path="/quiz-questions">
					<QuizQuestionsPage />
				</UserRoute>
				<UserRoute path="/trophies">
					<TrophiesPage />
				</UserRoute>
				<UserRoute path="/revision-lessons">
					<RevisionLessonsPage />
				</UserRoute>
				<UserRoute path="/gifts">
					<GiftsPage />
				</UserRoute>
				<UserRoute path="/gift-donations">
					<GiftDonationsPage />
				</UserRoute>
				<UserRoute path="/bug-reports">
					<ReportsPage />
				</UserRoute>
				<UserRoute path="*">
					<NotFoundPage />
				</UserRoute>
			</Switch>
		</MainLayout>
	)
}

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { message } from 'antd'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} EditQuestionTypePayload
 * @property {string} _id
 * @property {string} description
 * @property {string} hint
 * @property {string} button
 * @property {string} correct_feedback
 * @property {string} try_again_feedback
 * @property {string} incorrect_feedback
 * @property {bool} hint_show_answer
 */

/**
 * @typedef {object} UpdateQuestionTypeIconPayload
 * @property {string} question_type_id
 * @property {string} icon
 */

function useQuestionTypes() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const { enableUpdateStatistics } = useUser()

	const fetchQuestionTypes = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin_question_type?size=100')
				.then(({ data: questionTypes }) => {
					const transformedData = questionTypes.sort((a, b) =>
						a.name > b.name ? 1 : -1
					)
					setData(transformedData)
					resolve(questionTypes)
				})
				.catch((err) => {
					reject(err)
				})
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchQuestionType = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) reject(new Error(`question type id is not provided`))
				else {
					setLoading(true)
					axios
						.get(`/admin_question_type/${id}`)
						.then(({ data: questionType }) => resolve(questionType))
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const updateQuestionTypeIcon = useCallback(
		/**
		 * @param {UpdateQuestionTypeIconPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { question_type_id } = payload
				message.loading({
					content: 'updating question type hint icon...',
					key: question_type_id
				})
				axios
					.post('/admin_question_type/icon_ids', payload)
					.then(({ data: questionTypeNewIcon }) => {
						message.success({
							content: 'question type hint icon updated',
							key: question_type_id
						})
						resolve(questionTypeNewIcon)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update question type hint icon',
							key: question_type_id
						})
						reject(err)
					})
			})
		},
		[]
	)

	const editQuestionType = useCallback(
		/**
		 * @param {EditQuestionTypePayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon, ...restPayload } = payload
				const { _id: question_type_id } = restPayload
				message.loading({
					content: 'editing question type data...',
					key: question_type_id
				})

				axios
					.put('/admin_question_type', restPayload)
					.then(({ data: editedQT }) => {
						message.success({
							content: 'question type data updated',
							key: question_type_id
						})
						enableUpdateStatistics()
						if (icon) {
							updateQuestionTypeIcon({ question_type_id, icon })
								.then((updatedQTIcon) => {
									Object.assign(editedQT, updatedQTIcon)
								})
								.finally(() => {
									fetchQuestionTypes()
									resolve(editedQT)
								})
						} else {
							fetchQuestionTypes()
							resolve(editedQT)
						}
					})
					.catch((err) => {
						message.error({
							content: 'failed to update question type',
							key: question_type_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestionTypes, updateQuestionTypeIcon]
	)

	return {
		data,
		isLoading,
		fetchQuestionTypes,
		editQuestionType,
		fetchQuestionType
	}
}
export default useQuestionTypes

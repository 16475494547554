/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import {
	EditOutlined,
	MenuOutlined,
	PlusOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { Button, message, Select, Table } from 'antd'
import qs from 'query-string'

import {
	getSearchFilterProps,
	getSelectFilterProps,
	QuestionModal
} from '../../components'
import {
	useLevels,
	useQuestions,
	useQuestionTypes,
	useSubjects
} from '../../hooks'

const ADD_MODAL = {
	title: 'New Micro Lesson',
	ctaBtnText: 'ADD NEW MICRO LESSON'
}
const EDIT_MODAL = {
	title: 'Edit Micro Lesson',
	ctaBtnText: 'Save'
}

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export default function MicroLessonsPage() {
	const [modal, setModal] = useState()

	const {
		data,
		totalCount,
		isLoading,
		fetchQuestions,
		addNewQuestion,
		deleteQuestion,
		editQuestion,
		bulkUploadQuestions
	} = useQuestions()

	const fileInputRef = useRef(null)
	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(() => setModalVisibility(false), [
		setModalVisibility
	])

	const {
		data: subjectsData,
		fetchSubjects,
		isLoading: subjectsIsLoading
	} = useSubjects()

	const { data: levelsData, fetchAllLevels } = useLevels()

	const {
		data: questionTypes,
		fetchQuestionTypes,
		isLoading: questionTypesIsLoading
	} = useQuestionTypes()

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) {
				let paramKey
				switch (key) {
					case 'question':
						paramKey = 'question_text'
						break
					case 'type':
						paramKey = 'question_type_id'
						break
					case 'subject':
						paramKey = 'subject_id'
						break
					case 'level':
						paramKey = 'level_index'
						break
					case 'lesson':
						paramKey = 'lesson_title'
						break
					default:
						paramKey = key
						break
				}
				Object.assign(params, { [paramKey]: value[0] })
			}
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}

		history.replace({
			search: qs.stringify(params, { skipNull: true, skipEmptyString: true })
		})

		fetchQuestions('admin_question', params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}
	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}
	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchQuestions('admin_question')
	}
	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	const onChangeFile = (e) => {
		e.stopPropagation()
		e.preventDefault()
		const file = e.target.files[0]
		if (file) {
			bulkUploadQuestions(file, 'microLessons').then(() =>
				fetchQuestions('admin_question', queryParams)
			)
		}
	}

	useEffect(() => {
		fetchQuestionTypes()
	}, [fetchQuestionTypes])

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	useEffect(() => {
		fetchAllLevels()
	}, [fetchAllLevels])

	useEffect(() => {
		fetchQuestions('admin_question', queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchQuestions])

	useEffect(() => {
		if (queryParams.mode === 'edit_question') {
			setModal(EDIT_MODAL)
		} else if (queryParams.mode === 'add') setModal(ADD_MODAL)
	}, [queryParams.mode])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const item = data[oldIndex]
			const min = Math.min(oldIndex, newIndex)
			const max = Math.max(oldIndex, newIndex)

			const filteredData = data
				.reduce((res, cur, index) => {
					res.push({ ...cur, index })
					return res
				}, [])
				.filter(
					(el, index) =>
						index >= min && index <= max && el?.lesson_id === item?.lesson_id
				)
			if (filteredData.length > 1) {
				let sequence
				if (oldIndex > newIndex) {
					// moved up
					const target = filteredData[0]
					sequence = target?.sequence
				} else {
					// moved down
					const target = filteredData[filteredData.length - 1]
					sequence = target?.sequence
				}
				editQuestion(
					{
						_id: item._id,
						type: item.question_type._id,
						lesson_id: item.lesson_id,
						details: item.details,
						sequence
					},
					'admin_question'
				)
			} else {
				message.error(
					'You can only reorder micro-lessons within the same lesson'
				)
			}
		}
	}

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => {
				const node = document.querySelector('.ant-table-tbody')
				// console.log(node)
				return node
			}}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold mr-auto">Micro Lessons List</h1>
				<Button
					disabled={resetFiltersDisabled}
					onClick={resetFilters}
					type="default"
					className="mr-2"
				>
					Reset All Filters
				</Button>
				<input
					type="file"
					ref={fileInputRef}
					className="hidden"
					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={onChangeFile}
				/>
				<Button
					type="primary"
					onClick={() => fileInputRef.current?.click()}
					icon={<UploadOutlined />}
					className="flex items-center mr-2"
				>
					BULK UPLOAD
				</Button>
				<Button
					type="primary"
					onClick={() => {
						setModalVisibility(true, { mode: 'add' })
					}}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				loading={isLoading}
				dataSource={data}
				className="mx-4 text-xs"
				rowClassName="micro-lesson-row text-xs"
				tableLayout="fixed"
				size="small"
				scroll={{
					y: 'calc(100vh - 242px)',
					x: 1360
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
				rowKey={(record) => record._id}
			>
				<Table.Column
					dataIndex="sort"
					key="sort"
					className="drag-handle-col text-center"
					width={48}
					title={
						<Button
							type={reordering ? 'primary' : 'default'}
							icon={<MenuOutlined />}
							className="w-full h-full flex items-center justify-center"
							onClick={() => setReordering((prev) => !prev)}
						/>
					}
					render={reordering ? () => <DragHandle /> : null}
				/>
				<Table.Column
					title="Subject"
					dataIndex={['subject', 'title']}
					key="subject"
					className="subject-title-col"
					width={148}
					{...getSelectFilterProps('subject', subjectsData, (record) => (
						<Select.Option value={record._id}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('subject_id')}
				/>
				<Table.Column
					title="Level"
					dataIndex={['level', 'index']}
					key="level"
					width={84}
					className="lvl-index-col text-center"
					sorter
					sortOrder={getSortOrder('sort_level')}
					{...getSelectFilterProps('level', levelsData, (record) => (
						<Select.Option value={record.index}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('level_index')}
				/>
				<Table.Column
					title="Lesson NO."
					dataIndex={['lesson', 'priority']}
					key="lesson_priority"
					className="lesson-number-col text-center"
					width={124}
					{...getSearchFilterProps('Lesson number', true)}
					filteredValue={getFilterValue('lesson_priority')}
				/>
				<Table.Column
					title="Lesson"
					dataIndex={['lesson', 'title']}
					key="lesson"
					className="lesson-title-col"
					width={198}
					{...getSearchFilterProps('lessons')}
					filteredValue={getFilterValue('lesson_title')}
				/>
				<Table.Column
					title="Micro Lesson NO."
					dataIndex="sequence"
					key="sequence"
					className="sequence-col highlighted-number text-center"
					width={184}
					{...getSearchFilterProps('Micro lesson number')}
					filteredValue={getFilterValue('sequence')}
				/>
				<Table.Column
					title="Q-Type"
					dataIndex={['question_type', 'name']}
					key="type"
					className="qt-name-col text-center"
					width={98}
					{...getSelectFilterProps('question type', questionTypes, (record) => (
						<Select.Option value={record._id}>{record.name}</Select.Option>
					))}
					filteredValue={getFilterValue('question_type_id')}
				/>
				<Table.Column
					title="Question"
					dataIndex={['details', 'questionText']}
					key="question"
					className="question-text-col"
					ellipsis
					{...getSearchFilterProps('questions')}
					filteredValue={getFilterValue('question_text')}
				/>
				<Table.Column
					title="Status"
					dataIndex="status"
					key="status"
					className="status-col text-center"
					width={98}
					{...getSelectFilterProps(
						'status',
						[
							{
								_id: 'active',
								title: 'Active'
							},
							{
								_id: 'inactive',
								title: 'Inactive'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('status')}
				/>
				{/* <Table.Column
					title="Last Modified"
					dataIndex="last_modified"
					key="last_modified"
					render={(val) => moment(val).format('DD MMM, YYYY')}
				/> */}
				<Table.Column
					key="actions"
					title="Actions"
					className="actions-col text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'edit_question',
										record_id: record._id
									})
								}}
							/>
							{/* <DeleteBtn onDelete={() => deleteQuestion(record._id)} /> */}
						</div>
					)}
				/>
			</Table>
			<QuestionModal
				questionCategory="admin_question"
				onClose={handleCloseModal}
				onEdit={editQuestion}
				onAdd={addNewQuestion}
				onDelete={deleteQuestion}
				title={modal?.title}
				ctaBtnText={modal?.ctaBtnText}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
				questionTypes={questionTypes}
				questionTypesIsLoading={questionTypesIsLoading}
				queryParams={queryParams}
			/>
		</>
	)
}

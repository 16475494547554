/* eslint-disable no-underscore-dangle */
import { useState } from 'react'
import { Form, InputNumber, Modal, Switch } from 'antd'
import PropTypes, { array, func } from 'prop-types'

import { confirmExit } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

export default function AddNewAvatarModal({
	visible,
	setVisible,
	onAdd,
	avatarsData
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()

	const closeModal = () => {
		form.resetFields()
		setVisible(false)
		setIsSubmitting(false)
	}

	const handleAddingNewData = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			onAdd(values)
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	const onCancel = () => {
		const formIsEmpty = Object.values(form.getFieldsValue()).every((el) => !el)

		confirmExit(closeModal, !formIsEmpty)
	}

	return (
		<Modal
			visible={visible}
			okText="ADD NEW AVATAR"
			onOk={handleAddingNewData}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={onCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">New Avatar</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_avatar"
				initialValues={{
					avatar_id: null,
					avatar_category: null,
					for_free: false,
					in_shop: false,
					coins: null,
					gem_level: null,
					icon_light: null
					// icon_dark: null
				}}
				labelCol={{
					span: 6
				}}
				onFinish={handleAddingNewData}
			>
				<Form.Item
					name="avatar_id"
					label="Avatar ID"
					rules={[
						{
							required: true,
							message: 'Please input an ID for avatar'
						},
						{
							validator: async (_, value) => {
								if (avatarsData.some((el) => el.avatar_id === value)) {
									return Promise.reject(new Error(`Avatar ID should be unique`))
								}

								return Promise.resolve()
							}
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="avatar_category"
					label="Avatar Category"
					rules={[
						{
							required: true,
							message: 'Please input a category for avatar'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item name="for_free" label="For Free" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item name="in_shop" label="In Shop" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item
					name="coins"
					label="Coins"
					rules={[
						{
							required: true,
							message: 'Please input the coins amount for purchase avatar'
						},
						{
							type: 'number',
							message: 'Coins amount should be a number'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="gem_level"
					label="Gem Level"
					rules={[
						{
							required: true,
							message: 'Please input the gem level of avatar'
						},
						{
							type: 'number',
							message: 'Gem level should be a number'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				{[
					{
						name: 'icon_light',
						label: 'Icon'
					}
					/*
					,
					{
						name: 'icon_dark',
						label: 'Dark Icon'
					}
					*/
				].map((icon) => (
					<Form.Item
						name={icon.name}
						key={icon.name}
						label={icon.label}
						rules={[
							{
								required: icon.name === 'icon_light',
								message: 'Please select icon file of avatar'
							}
						]}
						className="flex flex-row items-center"
					>
						<div className="flex flex-col items-start justify-start w-max">
							<SingleFileUploader
								type="avatar"
								onlyPNG
								afterUpload={(fileID) =>
									form.setFieldsValue({ [icon.name]: fileID })
								}
							/>
						</div>
					</Form.Item>
				))}
			</Form>
		</Modal>
	)
}
AddNewAvatarModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired,
	onAdd: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	avatarsData: array.isRequired
}

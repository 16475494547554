/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {('admin_question'|'admin_quiz'|'admin_revision' | 'admin_microrevision')} QuestionCategory
 */
/**
 * @typedef {object} Messages
 * @property {string} [loading]
 * @property {string} [success]
 * @property {string} [error]
 */

export default function useQuestions() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchQuestions = useCallback(
		/**
		 * @param {QuestionCategory} [questionCategory]
		 * @param {object} queryParams - request query parameters as an object
		 */
		(questionCategory = 'admin_question', queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/${questionCategory}?${qs.stringify(queryParams, {
					skipNull: true
				})}`

				axios
					.get(endpoint)
					.then(({ data: questions }) => {
						const { data: questionsData, total_count } = questions
						setTotalCount(total_count)
						const transformedData =
							questionCategory === 'admin_revision'
								? questionsData
								: questionsData.map((question) => {
										const { questionText, text, completeText } =
											question.details || {}
										return {
											...question,
											details: {
												...question.details,
												questionText:
													question.question_type.name === 'STS'
														? ''
														: questionText || completeText || text
											}
										}
								  })
						setData(transformedData)
						resolve(transformedData)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchQuestion = useCallback(
		/**
		 * @param {string} id - question ID
		 * @param {QuestionCategory} [questionCategory]
		 */
		(id, questionCategory = 'admin_question') => {
			return new Promise((resolve, reject) => {
				const endpoint = `/${questionCategory}/${id}`

				axios
					.get(endpoint)
					.then(({ data: question }) => {
						resolve(question)
					})
					.catch(reject)
			})
		},
		[]
	)

	/**
	 * @typedef {object} Options
	 * @property {bool} [refetchOnSuccess]
	 */
	const addNewQuestion = useCallback(
		/**
		 * @param {*} payload
		 * @param {QuestionCategory} [questionCategory]
		 * @param {Options} [options]
		 */
		(payload, questionCategory = 'admin_question', options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				const endpoint = `/${questionCategory}`
				axios
					.post(endpoint, payload)
					.then(({ data: newQuestion }) => {
						enableUpdateStatistics()
						if (refetchOnSuccess)
							fetchQuestions(questionCategory, qs.parse(search))
						resolve(newQuestion)
					})
					.catch((err) => {
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestions, search]
	)

	const deleteQuestion = useCallback(
		/**
		 * @param {string} id - question _id
		 * @param {QuestionCategory} [questionCategory]
		 * @param {Messages} [messages]
		 * @param {Options} [options]
		 */
		(id, questionCategory = 'admin_question', messages, options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnDone = true } = options || {}
				message.loading({
					content: messages?.loading || 'deleting question...',
					key: id
				})
				const endpoint = `/${questionCategory}/${id}`
				axios
					.delete(endpoint)
					.then(() => {
						message.success({
							content: messages?.success || 'question deleted',
							key: id
						})
						enableUpdateStatistics()
						if (refetchOnDone)
							fetchQuestions(questionCategory, qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: messages?.error || 'failed to delete question',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestions, search]
	)

	const editQuestion = useCallback(
		/**
		 * @param {*} payload
		 * @param {QuestionCategory} [questionCategory]
		 * @param {Options} [options]
		 */
		(payload, questionCategory = 'admin_question', options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				const endpoint = `/${questionCategory}`
				const { _id, id } = payload || {}
				message.loading({
					content: 'updating question data...',
					key: _id || id
				})
				axios
					.put(endpoint, payload)
					.then(({ data: questionData }) => {
						message.success({
							content: 'question data updated',
							key: _id || id
						})
						enableUpdateStatistics()
						if (refetchOnSuccess)
							fetchQuestions(questionCategory, qs.parse(search))
						resolve(questionData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update question data',
							key: _id || id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestions, search]
	)

	/**
	 * @param {File} file
	 * @param {'microLessons' | 'quizQuestions' | 'revisionLessons'} category
	 */
	const bulkUploadQuestions = (file, category) => {
		return new Promise((resolve, reject) => {
			let endpoint
			const error = new Error()
			switch (category) {
				case 'microLessons':
					endpoint = '/admin_subject/excel'
					break
				case 'quizQuestions':
					endpoint = '/admin_quiz/excel'
					break
				case 'revisionLessons':
					endpoint = '/admin_revision/excel'
					break
				default:
					message.error('Check console for errors')
					error.message =
						'question category submitted wrong, check wherever the bulkUpload method is called'
					// eslint-disable-next-line no-console
					console.error(error)
					reject(error)
					break
			}
			if (endpoint) {
				const key = Date.now()
				const fd = new FormData()
				fd.append('excel', file)
				message.loading({
					content: 'uploading and processing file...',
					key
				})
				axios
					.post(endpoint, fd, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						message.success({
							content: 'file uploaded and processed',
							key
						})
						enableUpdateStatistics()
						resolve(res)
					})
					.catch((err) => {
						message.error({
							content: 'failed to upload or process file',
							key
						})
						reject(err)
					})
			}
		})
	}

	return {
		data,
		isLoading,
		totalCount,
		fetchQuestions,
		editQuestion,
		deleteQuestion,
		addNewQuestion,
		bulkUploadQuestions,
		fetchQuestion
	}
}

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, Input, message, Modal, Spin, Switch } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object } from 'prop-types'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useSubjects } from '../../hooks'
import { confirmExit } from '../../utils'

export default function EditSubjectModal({
	onClose,
	onEdit,
	onDelete,
	queryParams
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)
	// const [color, setColor] = useState(undefined)

	const { fetchSubject } = useSubjects()
	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form

	const checkFormChanged = useCallback(() => {
		const { _id, user_id, levels, ...rest } = record || {}
		setFormHasChanged(!isEqual(getFieldsValue(), rest))
	}, [getFieldsValue, record])

	// const handleChangeColor = (col) => {
	// 	setColor(col)
	// 	checkFormChanged()
	// 	setFieldsValue({ color: col })
	// }

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		// setColor(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)

			const { icon_light, icon_dark, ...payload } = values
			Object.assign(payload, { _id: record._id })
			;['icon_light', 'icon_dark'].forEach((name) => {
				if (record[name] !== values[name])
					Object.assign(payload, { [name]: values[name] })
			})

			onEdit(payload)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const handleDelete = () => {
		onDelete(record._id).then(onClose)
	}

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchSubject(queryParams.record_id)
				.then((data) => {
					setRecord(data)
					// setColor(data.color)
					setFieldsValue(data)
				})
				.catch(() => {
					message.error("Couldn't fetch subject data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		onClose,
		fetchSubject,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={
				!!(queryParams?.modal_is_open && queryParams?.mode === 'edit') || false
			}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold">Edit Subject</h3>}
			confirmLoading={isSubmitting}
			afterClose={afterClose}
			forceRender
			destroyOnClose
			centered
			// onOk={handleSubmitEdit}
			// okText="Edit"
			// okButtonProps={{
			// 	disabled: !formHasChanged,
			// 	className: 'inline-flex flex-row items-center justify-center'
			// }}
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleCancelEdit}>Cancel</Button>
					<Button
						type="primary"
						disabled={!formHasChanged}
						onClick={handleSubmitEdit}
						className="inline-flex flex-row items-center justify-center"
					>
						Save
					</Button>
				</div>
			}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="edit_subject"
					labelCol={{
						span: 5
					}}
					onFieldsChange={checkFormChanged}
					preserve={false}
				>
					<Form.Item
						name="title"
						label="Title"
						rules={[
							{
								required: true,
								message: 'Please input the title of subject!'
							}
						]}
					>
						<Input autoFocus />
					</Form.Item>
					<Form.Item
						name="description"
						label="Description"
						rules={[
							{
								required: true,
								message: 'Please input the description of subject!'
							}
						]}
					>
						<Input.TextArea rows={3} />
					</Form.Item>
					<Form.Item label="Color">
						{/* <ColorPicker color={color} setColor={handleChangeColor} /> */}
						<div
							className="w-10 h-5 rounded-sm"
							style={{
								backgroundColor: record?.color || 'white'
							}}
						/>
					</Form.Item>
					<Form.Item name="active" label="Active" valuePropName="checked">
						<Switch />
					</Form.Item>
					{[
						{
							name: 'icon_light',
							label: 'Light Icon'
						},
						{
							name: 'icon_dark',
							label: 'Dark Icon'
						}
					].map((icon) => (
						<Form.Item
							name={icon.name}
							key={icon.name}
							label={icon.label}
							rules={[
								{
									required: true,
									message: 'Please select icon file of subject'
								}
							]}
							className="flex flex-row items-center"
							getValueFromEvent={() => record?.[icon.name]}
						>
							<div>
								<SingleFileUploader
									type="subject"
									onlyPNG
									defaultFile={record?.[icon.name]}
									afterUpload={(fileID) => {
										setFieldsValue({ [icon.name]: fileID })
										checkFormChanged()
									}}
								/>
							</div>
						</Form.Item>
					))}
				</Form>
			</Spin>
		</Modal>
	)
}
EditSubjectModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	onDelete: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired
}

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form, message, Modal, Spin } from 'antd'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import qs from 'query-string'

import { DeleteBtn } from '../../components'
import { useQuestions } from '../../hooks'

import RevisionLessonForm from './revision-lesson-form'
import RevisionQuestionsForm from './revision-question-form'

// import s from './modal.module.scss'

export default function QuestionModal({
	onClose,
	onAdd,
	onEdit,
	onDelete,
	title,
	isVisible,
	ctaBtnText,
	subjects,
	subjectsIsLoading,
	questionTypes,
	questionTypesIsLoading,
	fetchQuestions
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [revisionLessonDataForm] = Form.useForm()
	const [questionDetailsFormInstance] = Form.useForm()
	// const [step, setStep] = useState(1)
	const [record, setRecord] = useState(undefined)
	const [selectedType, setSelectedType] = useState(undefined)

	const history = useHistory()
	const { fetchQuestion } = useQuestions()

	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const modalIsVisible = useMemo(() => {
		if (isVisible !== undefined) return isVisible
		const { modal_is_open, mode, record_id } = queryParams || {}
		return (
			modal_is_open &&
			((mode === 'edit_revision' && record_id) || mode === 'add')
		)
	}, [isVisible, queryParams])

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setSelectedType(undefined)
		// setStep(1)
		revisionLessonDataForm.resetFields()
		questionDetailsFormInstance.resetFields()
	}, [questionDetailsFormInstance, revisionLessonDataForm])

	const handleSubmit = () => {
		revisionLessonDataForm.validateFields().then((values) => {
			questionDetailsFormInstance.validateFields().then((details) => {
				// setIsSubmitting(true)
				if (queryParams.mode === 'add') {
					const { question, ...rest } = values
					const payload = {
						...rest,
						questions: [
							{
								...question,
								details
								// details: {
								// 	...details,
								// 	status: values.status ? 'active' : 'inactive'
								// }
							}
						]
					}
					onAdd(payload)
						.then(() => {
							onClose()
							fetchQuestions?.('admin_revision', queryParams)
						})
						.finally(() => setIsSubmitting(false))
				} else if (queryParams.mode === 'edit_revision') {
					// eslint-disable-next-line camelcase
					const { lesson_id } = values || {}
					const payload = {
						...values.question,
						id: record.id,
						lesson_id,
						details
					}
					onEdit(payload)
						.then(() => {
							onClose()
							fetchQuestions?.('admin_revision', queryParams)
						})
						.finally(() => setIsSubmitting(false))
				}
			})
		})
	}

	useEffect(() => {
		if (
			questionTypes.length &&
			queryParams.mode === 'edit_revision' &&
			queryParams?.record_id
		) {
			setIsFetching(true)
			fetchQuestion(queryParams.record_id, 'admin_microrevision')
				.then((data) => {
					setRecord(data)
					setSelectedType(
						questionTypes.find((el) => el._id === data.question_type._id)
					)
				})
				.catch(() => {
					message.error("Couldn't fetch question data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionTypes, fetchQuestion, modalIsVisible])

	const handleDelete = () => {
		onDelete(record.revision._id).then(onClose)
	}

	return (
		<Modal
			visible={modalIsVisible}
			onCancel={onClose}
			title={<h3 className="font-bold">{title}</h3>}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			className="w-full"
			// wrapClassName={s.modal}
			// okText={ctaBtnText}
			// onOk={handleSubmit}
			// okButtonProps={{
			// 	className: 'inline-flex items-center justify-center leading-none'
			// }}
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={onClose}>Cancel</Button>
					<Button
						type="primary"
						onClick={handleSubmit}
						className="inline-flex items-center justify-center leading-none"
					>
						{ctaBtnText}
					</Button>
				</div>
			}
			// footer={
			// 	<div className="flex flex-row items-center justify-between">
			// 		{step === 2 && <Button onClick={() => setStep(1)}>Previous</Button>}
			// 		<span className="mr-auto" />
			// 		<Button danger onClick={onClose}>
			// 			Cancel
			// 		</Button>
			// 		{step === 1 && (
			// 			<Button
			// 				onClick={() =>
			// 					revisionLessonDataForm.validateFields().then(() => setStep(2))
			// 				}
			// 			>
			// 				Next
			// 			</Button>
			// 		)}
			// 		{step === 2 && (
			// 			<Button type="primary" onClick={handleSubmit}>
			// 				Submit
			// 			</Button>
			// 		)}
			// 	</div>
			// }
		>
			<Spin spinning={isFetching}>
				<RevisionLessonForm
					// active={step === 1}
					formInstance={revisionLessonDataForm}
					subjects={subjects}
					subjectsIsLoading={subjectsIsLoading}
					initialValues={record}
					questionTypes={questionTypes}
					questionTypesIsLoading={questionTypesIsLoading}
					setSelectedType={setSelectedType}
				/>
				<RevisionQuestionsForm
					selectedTypeName={selectedType?.name}
					// active={step === 2}
					formInstance={questionDetailsFormInstance}
					initialValues={record}
				/>
			</Spin>
		</Modal>
	)
}
QuestionModal.propTypes = {
	// setVisible: func.isRequired,
	onClose: func.isRequired,
	onAdd: func,
	onEdit: func,
	onDelete: func,
	// eslint-disable-next-line react/forbid-prop-types
	title: string.isRequired,
	ctaBtnText: string.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired,
	questionTypes: arrayOf(
		shape({
			_id: string,
			name: string
		})
	).isRequired,
	questionTypesIsLoading: bool.isRequired,
	fetchQuestions: func,
	isVisible: bool
}

QuestionModal.defaultProps = {
	onAdd: undefined,
	onEdit: undefined,
	onDelete: undefined,
	isVisible: undefined,
	fetchQuestions: undefined
}

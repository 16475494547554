import { useCallback, useEffect, useState } from 'react'
import { Form, Input, Modal, Switch } from 'antd'
import { bool, func } from 'prop-types'

import { confirmExit } from '../../utils'
import ColorPicker from '../color-picker'
import SingleFileUploader from '../single-file-uploader'

export default function AddNewSubjectModal({ visible, setVisible, onAdd }) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()
	const [color, setColor] = useState('')

	const handleChangeColor = (col) => {
		setColor(col)
		form.setFieldsValue({ color: col })
	}

	const closeModal = () => {
		form.resetFields()
		setIsSubmitting(false)
		setVisible(false)
	}

	const onCancel = () => {
		const formIsEmpty = Object.values(form.getFieldsValue()).every((el) => !el)

		confirmExit(closeModal, !formIsEmpty)
	}

	const handleAddNewData = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			onAdd(values)
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	const resetColor = useCallback(() => {
		setColor('')
		form.setFieldsValue({ color: '' })
	}, [form])

	useEffect(() => {
		if (visible) {
			resetColor()
		}
	}, [resetColor, visible])

	return (
		<Modal
			visible={visible}
			okText="ADD NEW SUBJECT"
			onOk={handleAddNewData}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={onCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">New Subject</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_subject"
				labelCol={{
					span: 5
				}}
				initialValues={{
					title: '',
					description: '',
					color,
					icon_light: null,
					icon_dark: null,
					active: false
				}}
			>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title of subject!'
						}
					]}
				>
					<Input autoFocus />
				</Form.Item>
				<Form.Item
					name="description"
					label="Description"
					rules={[
						{
							required: true,
							message: 'Please input the description of subject!'
						}
					]}
				>
					<Input.TextArea rows={3} />
				</Form.Item>
				<Form.Item
					name="color"
					label="Color"
					rules={[
						{
							required: true,
							message: 'Please select a color for subject!'
						}
					]}
				>
					<ColorPicker color={color} setColor={handleChangeColor} />
				</Form.Item>
				<Form.Item name="active" label="Active" valuePropName="checked">
					<Switch />
				</Form.Item>
				{[
					{
						name: 'icon_light',
						label: 'Light Icon'
					},
					{
						name: 'icon_dark',
						label: 'Dark Icon'
					}
				].map((icon) => (
					<Form.Item
						name={icon.name}
						key={icon.name}
						label={icon.label}
						rules={[
							{
								required: true,
								message: 'Please select icon file of subject'
							}
						]}
						className="flex flex-row items-center"
					>
						<div>
							<SingleFileUploader
								type="subject"
								onlyPNG
								afterUpload={(fileID) =>
									form.setFieldsValue({ [icon.name]: fileID })
								}
							/>
						</div>
					</Form.Item>
				))}
			</Form>
		</Modal>
	)
}
AddNewSubjectModal.propTypes = {
	visible: bool.isRequired,
	setVisible: func.isRequired,
	onAdd: func.isRequired
}

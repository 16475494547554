import { Redirect, Route } from 'react-router-dom'

export default function UserRoute(props) {
	const { children, ...rest } = props || {}

	const access = localStorage.getItem('access_token')

	return (
		<Route
			{...rest}
			render={({ location }) =>
				access ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/auth/login',
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}

import {
	AssignNewAdmin,
	// InfoCard,
	RecentActivityItem,
	ViewListCard
} from '../../components'
import { useUser } from '../../store/hooks'

export function HomePage() {
	const user = useUser()

	return (
		<>
			<section className="h-64 bg-gray-200">
				<div
					className="w-full py-6 md:w-1/4 xl:w-1/5 h-full flex flex-col items-center justify-around text-center"
					style={{ minWidth: '256px' }}
				>
					<h1>ADMIN DASHBOARD</h1>
					<img
						src={user.avatar}
						className="w-16 h-16 rounded-full"
						alt="profile"
					/>
					{user.name ? (
						<span className="uppercase text-center">
							HELLO{`, ${user.name}`}
						</span>
					) : (
						<span className="animate-pulse space-x-4 space-y-4 h-6 w-24 bg-gray-300 rounded-sm" />
					)}
					<AssignNewAdmin />
				</div>
			</section>
			<div className="flex flex-col-reverse md:flex-row">
				<section
					className="w-full md:w-1/4 xl:w-1/5 pt-4 pb-2 px-4"
					style={{ minWidth: '256px' }}
				>
					<h2 className="text-gray-300 text-sm">Updates</h2>
					{user.statistics?.activities.map((el) => (
						<RecentActivityItem key={el.date} item={el} />
					))}
				</section>
				<div className="mt-0 md:-mt-52 w-full md:w-3/4 xl:w-4/5">
					<section className="flex flex-row flex-wrap items-center justify-center">
						<ViewListCard
							title="question types"
							quantity={user.statistics?.question_type_count}
							viewListPath="/question-types"
						/>
						<ViewListCard
							title="subjects"
							quantity={user.statistics?.subject_count}
							viewListPath="/subjects"
							createNewPath="/subjects?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="levels"
							quantity={user.statistics?.level_count}
							viewListPath="/levels"
							createNewPath="/levels?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="lessons"
							quantity={user.statistics?.lesson_count}
							viewListPath="/lessons"
							createNewPath="/lessons?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="micro lessons"
							quantity={user.statistics?.question_count}
							viewListPath="/micro-lessons"
							createNewPath="/micro-lessons?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="revision lessons"
							quantity={user.statistics?.revision_count}
							viewListPath="/revision-lessons"
							createNewPath="/revision-lessons?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="quiz questions"
							quantity={user.statistics?.quiz_count}
							viewListPath="/quiz-questions"
							createNewPath="/quiz-questions?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="trophies"
							quantity={user.statistics?.trophy_count}
							viewListPath="/trophies"
						/>
						<ViewListCard
							title="avatars"
							quantity={user.statistics?.avatar_count}
							viewListPath="/avatars"
							createNewPath="/avatars?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="gifts"
							quantity={user.statistics?.gift_count}
							viewListPath="/gifts"
							createNewPath="/gifts?modal_is_open=true&mode=add"
						/>
						<ViewListCard
							title="Bug Reports"
							quantity={user.statistics?.bug_report_count}
							viewListPath="/bug-reports"
						/>
						<ViewListCard
							title="users"
							quantity={user.statistics?.user_count}
							viewListPath="/users"
						/>
					</section>
					{/* <section className="flex flex-row flex-wrap items-center justify-center">
						<InfoCard title="Total Users" amount="1.2 K" />
						<InfoCard title="Exams Proceed" amount="25 K" />
						<InfoCard title="Quantified Candidates" amount="45%" />
					</section> */}
				</div>
			</div>
		</>
	)
}

export default HomePage

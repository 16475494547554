import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

const { confirm } = Modal
const logoutConfirm = () => {
	return confirm({
		title: 'You are about to logout',
		icon: <ExclamationCircleOutlined />,
		content:
			'after logout, you need to re-enter your credential to use system again.',
		okText: 'Logout',
		okType: 'danger',
		onOk() {
			localStorage.clear()
			window.history.pushState(null, 'logout', '/auth/login')
			window.location.reload()
		},
		onCancel() {
			// do nothing
		}
	})
}

export default logoutConfirm

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Modal, Spin } from 'antd'
import isPlainObject from 'lodash.isplainobject'
import { bool, func, object } from 'prop-types'

import { useUsers } from '../../hooks'

const displayObject = (obj) => {
	return (
		<ul className="flex flex-col items-start px-2 w-full text-base">
			{obj &&
				Object.entries(obj).map(([key, value]) => {
					if (typeof value === 'string' || typeof value === 'number') {
						return (
							<li className="flex justify-center" key={key}>
								<span className="font-bold">{key}:&nbsp;</span>
								<span className="break-all">{value}</span>
							</li>
						)
					}
					if (typeof value === 'boolean') {
						return (
							<li className="flex items-center justify-center" key={key}>
								<span className="font-bold">{key}:&nbsp;</span>
								<span>
									{value === true ? (
										<CheckOutlined className="text-green-500" />
									) : (
										<CloseOutlined className="text-red-500" />
									)}
								</span>
							</li>
						)
					}
					if (typeof value === 'object' && isPlainObject(value)) {
						return (
							<div className="flex-col" key={key}>
								<span className="font-bold">{key}:&nbsp;</span>
								<span className="pl-2">{displayObject(value)}</span>
							</div>
						)
					}
					if (typeof value === 'object' && Array.isArray(value)) {
						if (value.every((el) => isPlainObject(el))) {
							return (
								<ul className="flex flex-col" key={key}>
									<span className="font-bold">{key}:&nbsp;</span>
									<span className="pl-2">
										{value.map((subValue, i) => (
											// eslint-disable-next-line react/no-array-index-key
											<li className="py-1 border-b border-black" key={i}>
												{displayObject(subValue)}
											</li>
										))}
									</span>
								</ul>
							)
						}
						return (
							<li key={key}>
								<span>{key}:&nbsp;</span>
								<span className="pl-2">{value.join(', ')}</span>
							</li>
						)
					}
					return null
				})}
		</ul>
	)
}

export default function ViewUserModal({ onClose, queryParams, isVisible }) {
	const { fetchUser } = useUsers()
	const [user, setUser] = useState(undefined)
	const [isFetching, setIsFetching] = useState(true)

	const modalIsVisible = useMemo(() => {
		if (typeof isVisible === 'boolean') return isVisible
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'view_user' && record_id
	}, [queryParams, isVisible])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchUser(queryParams.record_id)
				.then((userData) => {
					let childs = []
					const formattedData = Object.keys(userData).reduce((res, curKey) => {
						switch (curKey) {
							// case 'timezone':
							// case 'verified':
							case 'name':
							case 'email':
								Object.assign(res, { [curKey]: userData[curKey] })
								return res
							case 'childs':
								childs = userData.childs.map((child) => ({
									name: child?.name,
									coins: child?.coins,
									gems: child?.gems,
									trophies: child?.trophies?.reduce(
										(trophies, cur) =>
											cur.collected ? trophies + 1 : trophies,
										0
									),
									'highest streak': child?.highest_streak,
									'current streak': child?.streaks
								}))
								Object.assign(res, { childs })
								return res
							case 'subscription':
								Object.assign(res, { [curKey]: userData[curKey].status })
								return res
							default:
								return res
						}
					}, {})
					setUser(formattedData)
				})
				.finally(() => setIsFetching(false))
		} else setUser(undefined)
	}, [fetchUser, modalIsVisible, queryParams.record_id])

	return (
		<Modal
			visible={modalIsVisible}
			footer={null}
			closable
			destroyOnClose
			onCancel={onClose}
			className="overflow-x-hidden"
		>
			<Spin spinning={isFetching}>{displayObject(user)}</Spin>
		</Modal>
	)
}
ViewUserModal.propTypes = {
	onClose: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired,
	isVisible: bool
}
ViewUserModal.defaultProps = {
	isVisible: undefined
}

import { useCallback, useState } from 'react'
import { Form, Input, Modal } from 'antd'
import PropTypes from 'prop-types'

import { useUser } from '../../store/hooks'
import { confirmExit } from '../../utils'

import { UploadAvatar } from './upload-avatar'

export function ProfileModal(props) {
	const { visible, setVisible } = props
	const hideModal = () => setVisible(false)

	const user = useUser()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()
	const [dataHasChanged, setDataHasChanged] = useState(false)

	const detectFormHasChanged = useCallback(() => {
		const curFormData = form.getFieldsValue()
		const { name, email } = user
		const hasChanged = curFormData.name !== name || curFormData.email !== email
		setDataHasChanged(hasChanged)
	}, [form, user])

	const handleEditProfile = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			const { name, email } = values
			user
				.updateProfile({ name, email })
				.then(() => {
					user.updateState(form.getFieldsValue())
					hideModal()
				})
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleCancelEditing = () => {
		confirmExit(hideModal, dataHasChanged)
	}

	return (
		<Modal
			visible={visible}
			okText="Edit"
			onOk={handleEditProfile}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none',
				disabled: !dataHasChanged
			}}
			onCancel={handleCancelEditing}
			confirmLoading={isSubmitting}
			title="Profile"
			destroyOnClose
		>
			<UploadAvatar className="mb-4" />
			<Form
				form={form}
				name="edit_profile"
				initialValues={{
					name: user.name,
					email: user.email
				}}
				onFinish={handleEditProfile}
				onFieldsChange={detectFormHasChanged}
				labelCol={{
					span: 3
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input your name!'
						}
					]}
				>
					<Input placeholder="Name" type="text" />
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					rules={[
						{
							required: true,
							message: 'Please input your email!'
						},
						{
							type: 'email',
							message: 'Email is not valid!'
						}
					]}
				>
					<Input placeholder="Email" type="email" />
				</Form.Item>
			</Form>
		</Modal>
	)
}
ProfileModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired
}
export default ProfileModal

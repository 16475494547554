/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { useTrophies } from '../../hooks'
import { UPLOADS_SERVER_URL } from '../../utils'

import TrophyModal from './trophy-modal'

function QuestionTypesPage() {
	const { isLoading, data, fetchTrophies, editTrophy } = useTrophies()

	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(() => setModalVisibility(false), [
		setModalVisibility
	])

	useEffect(() => {
		fetchTrophies()
	}, [fetchTrophies])

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Trophies List</h1>
			</header>
			<Table
				loading={isLoading}
				dataSource={data}
				className="mx-4"
				scroll={{
					y: 'calc(100vh - 239px)',
					x: 1024
				}}
				pagination={false}
				tableLayout="fixed"
				size="small"
				rowKey={(record) => record._id}
			>
				<Table.Column
					dataIndex="order"
					title="Order"
					key="order"
					width={56}
					className="text-center"
				/>
				<Table.Column dataIndex="name" title="Name" key="name" />
				<Table.Column
					dataIndex="description"
					title="Description"
					key="description"
				/>
				<Table.Column
					dataIndex="level1"
					title="Level 1"
					key="level1"
					className="text-center"
				/>
				<Table.Column
					dataIndex="level2"
					title="Level 2"
					key="level2"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="level3"
					title="Level 3"
					key="level3"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="level4"
					title="Level 4"
					key="level4"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="level5"
					title="Level 5"
					key="level5"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="icon"
					title="Icon"
					key="icon"
					className="text-center"
					render={(val) => (
						<div className="h-8 overflow-hidden">
							{val ? (
								<img
									src={`${UPLOADS_SERVER_URL}${val}`}
									alt=""
									className="h-full w-auto mx-auto"
								/>
							) : (
								'not set'
							)}
						</div>
					)}
				/>
				<Table.Column
					title="Actions"
					className="w-24 text-center"
					key="actions"
					width={68}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}}
							/>
						</div>
					)}
				/>
			</Table>
			<TrophyModal
				onClose={handleCloseModal}
				onEdit={editTrophy}
				queryParams={queryParams}
			/>
		</>
	)
}

export default QuestionTypesPage

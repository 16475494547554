/* eslint-disable no-underscore-dangle */
import { useState } from 'react'
import { Form, Input, InputNumber, Modal, Switch } from 'antd'
import { bool, func } from 'prop-types'

import { confirmExit } from '../../utils'

export default function AddNewLevelModal({ visible, setVisible, onAdd }) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()

	const closeModal = () => {
		form.resetFields()
		setVisible(false)
		setIsSubmitting(false)
	}

	const handleAddingNewLevel = () => {
		form.validateFields().then((values) => {
			onAdd(values).then(closeModal)
		})
	}

	const handleCancel = () => {
		const formIsEmpty = Object.values(form.getFieldsValue()).every((el) => !el)
		confirmExit(closeModal, !formIsEmpty)
	}

	return (
		<Modal
			visible={visible}
			okText="ADD NEW LEVEL"
			onOk={handleAddingNewLevel}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={handleCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="m-0 font-bold">New Level</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_level"
				initialValues={{
					index: null,
					title: '',
					active: false
				}}
				labelCol={{
					span: 4
				}}
				preserve={false}
				onFinish={handleAddingNewLevel}
			>
				<Form.Item
					name="index"
					label="Index"
					rules={[
						{
							required: true,
							message: 'Please input the index of level'
						},
						{
							min: 1,
							pattern: /^(?![0]$)\d*$/,
							message: 'Only positive integers'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title of level'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="active" label="Active" valuePropName="checked">
					<Switch />
				</Form.Item>
			</Form>
		</Modal>
	)
}
AddNewLevelModal.propTypes = {
	visible: bool.isRequired,
	setVisible: func.isRequired,
	onAdd: func.isRequired
}

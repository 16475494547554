import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { NotFoundPage } from '../../pages'
import GuestRouter from '../guest-router'
import UserRouter from '../user-router'

export default function MainRouter() {
	return (
		<Router>
			<Switch>
				<Route path="/auth" component={GuestRouter} />
				<Route path="/" component={UserRouter} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</Router>
	)
}

/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { CheckOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import qs from 'query-string'

import { getSearchFilterProps, getSelectFilterProps } from '../../components'
import { useUsers } from '../../hooks'

import ViewUserModal from './view-user-modal'

export default function UsersPage() {
	const { isLoading, data, totalCount, fetchUsers } = useUsers()

	const history = useHistory()
	const queryParams = useMemo(() => qs.parse(history.location.search), [
		history.location.search
	])

	const setModalVisibility = (isVisible, qParams) => {
		const params = qs.parse(history.location.search)
		if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
		else {
			delete params.modal_is_open
			delete params.mode
			delete params.record_id
		}

		history.replace({
			search: qs.stringify(params)
		})
	}

	useEffect(() => {
		fetchUsers(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchUsers])

	const handleTableChange = (pagination, filters) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) Object.assign(params, { [key]: value[0] })
		})

		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchUsers(params)
	}

	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchUsers()
	}

	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Users List</h1>
				<Button
					disabled={resetFiltersDisabled}
					onClick={resetFilters}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
			</header>
			<Table
				className="mx-4"
				scroll={{
					y: 'calc(100vh - 239px)',
					x: 800
				}}
				dataSource={data}
				tableLayout="fixed"
				size="small"
				loading={isLoading}
				rowKey={(record) => record._id}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					dataIndex="name"
					title="Name"
					key="name"
					filteredValue={getFilterValue('name')}
					{...getSearchFilterProps('name')}
				/>
				<Table.Column
					dataIndex="email"
					title="Email"
					key="email"
					filteredValue={getFilterValue('email')}
					{...getSearchFilterProps('email')}
				/>
				<Table.Column
					dataIndex="verified"
					title="Verified"
					key="verified"
					width={84}
					{...getSelectFilterProps(
						'verified status',
						[
							{
								_id: true,
								title: 'Verified'
							},
							{
								_id: false,
								title: 'Not Verified'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('verified')}
					render={(val) => (
						<div className="flex items-center justify-center">
							{val ? (
								<CheckOutlined className="text-green-500" />
							) : (
								<CloseOutlined className="text-red-500" />
							)}
						</div>
					)}
				/>
				<Table.Column
					title="Actions"
					className="text-center"
					key="actions"
					width={84}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EyeOutlined />}
								className="edit-btn bg-gray-200 hover:border-transparent"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'view_user',
										record_id: record._id
									})
								}}
							/>
						</div>
					)}
				/>
			</Table>
			<ViewUserModal
				onClose={() => setModalVisibility(false)}
				queryParams={queryParams}
			/>
		</>
	)
}

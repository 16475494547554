import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	EyeInvisibleOutlined,
	EyeTwoTone,
	LockOutlined,
	UserOutlined
} from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import cn from 'classnames'

import logo from '../../assets/img/logo.png'
import { useUser } from '../../store/hooks'

import styles from './styles.module.scss'

export default function LoginPage() {
	const history = useHistory()
	const [form] = Form.useForm()
	const [isLoading, setIsLoading] = useState(false)

	const user = useUser()

	const handleSubmit = () => {
		setIsLoading(true)

		form.validateFields().then((values) => {
			user
				.login(values)
				.then((data) => {
					message.success(
						`Welcome back${data.user.name ? `, ${data.user.name}!` : '!'}`
					)
					history.replace('/')
				})
				.finally(() => setIsLoading(false))
		})
	}
	return (
		<div
			className={cn(
				styles['login-page'],
				'login-page h-full w-full flex flex-col items-center justify-center'
			)}
		>
			<div className="max-w-xs m-4 hidden sm:block">
				<img
					src={logo}
					alt="Deenee logo"
					className="h-full object-contain mr-5"
				/>
			</div>
			<div className="w-full h-full flex flex-col items-center justify-center sm:w-2/3 md:w-3/5 lg:w-1/2 2xl:w-1/3 sm:h-auto bg-white px-12 py-5 sm:m-4 bg-opacity-95 rounded-sm shadow-xl">
				<h1 className="text-xl mb-10">Admin Panel</h1>
				<Form
					form={form}
					name="login"
					className="w-full"
					initialValues={{ remember: true }}
					onFinish={handleSubmit}
				>
					<Form.Item
						name="email"
						rules={[
							{ required: true, message: 'Please input your email' },
							{ type: 'email', message: 'Email is invalid' }
						]}
					>
						<Input
							prefix={<UserOutlined className="form-item-icon" />}
							placeholder="Username"
							type="email"
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Please input your Password' }]}
					>
						<Input.Password
							prefix={<LockOutlined className="form-item-icon" />}
							placeholder="Password"
							iconRender={(visible) =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>

					<Form.Item name="submit">
						<Button
							type="primary"
							htmlType="submit"
							className="flex items-center justify-center"
							block
							size="large"
							loading={isLoading}
						>
							Log in
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddLevelPayload
 * @property {number} index
 * @property {string} title
 */

/**
 * @typedef {object} EditLevelPayload
 * @property {string} _id
 * @property {number} index
 * @property {string} title
 */

/**
 * @typedef {object} EditLevelsBtIndexPayload
 * @property {number} index
 * @property {string} title
 */

const useLevels = () => {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchLevels = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin_level?${qs.stringify(queryParams, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then((res) => {
						const { data: levels, total_count } = res.data
						setTotalCount(total_count)
						setData(levels)
						resolve(levels)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchAllLevels = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin_level/all?size=100')
				.then(({ data: { data: levels, total_count } }) => {
					setTotalCount(total_count)
					const transformed = levels.sort((a, b) => a.index - b.index)
					setData(transformed)
					resolve(transformed)
				})
				.catch(reject)
				.finally(() => setLoading(false))
		})
	}, [])

	const addNewLevel = useCallback(
		/**
		 * @param {AddLevelPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const key = Date.now()
				message.loading({
					content: 'adding new level...',
					key
				})

				axios
					.post('/admin_level', payload)
					.then(() => {
						message.success({
							content: 'new level added',
							key
						})
						enableUpdateStatistics()
						fetchAllLevels()
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new level',
							key
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAllLevels]
	)

	const deleteLevel = useCallback(
		/**
		 * @param {string} id - level _id
		 */
		(id) => {
			return new Promise((_resolve, reject) => {
				message.loading({ content: 'deleting level...', key: id })
				axios
					.delete(`/admin_level/${id}`)
					.then(() => {
						message.success({
							content: 'level deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchLevels(qs.parse(search))
					})
					.catch((err) => {
						message.error({
							content: 'failed to delete level',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLevels, search]
	)

	const deleteLevelsByIndex = useCallback(
		/**
		 * @param {number} index
		 */
		(index) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting level...', key: index })
				axios
					.delete(`/admin_level/index/${index}`)
					.then(() => {
						message.success({
							content: 'level deleted',
							key: index
						})
						enableUpdateStatistics()
						fetchAllLevels()
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to delete level',
							key: index
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAllLevels]
	)

	const editLevel = useCallback(
		/**
		 * @param {EditLevelPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { _id: level_id } = payload
				message.loading({
					content: 'editing level data...',
					key: level_id
				})
				axios
					.put('/admin_level', payload)
					.then(({ data: editedLevel }) => {
						message.success({
							content: 'level data updated',
							key: level_id
						})
						enableUpdateStatistics()
						fetchLevels(qs.parse(search))
						resolve(editedLevel)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update level',
							key: level_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLevels, search]
	)

	const editLevelsByIndex = useCallback(
		/**
		 * @param {EditLevelsBtIndexPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { index } = payload
				message.loading({
					content: 'editing level data...',
					key: index
				})
				axios
					.put('/admin_level/index', payload)
					.then(({ data: editedLevel }) => {
						message.success({
							content: 'level data updated',
							key: index
						})
						enableUpdateStatistics()
						fetchAllLevels()
						resolve(editedLevel)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update level',
							key: index
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAllLevels]
	)

	return {
		data,
		isLoading,
		totalCount,
		fetchLevels,
		addNewLevel,
		deleteLevel,
		editLevel,
		fetchAllLevels,
		deleteLevelsByIndex,
		editLevelsByIndex
	}
}
export default useLevels

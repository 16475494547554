/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddAvatarPayload
 * @property {string} avatar_id
 * @property {string} avatar_category
 * @property {bool} for_free
 * @property {bool} in_shop
 * @property {number} coins
 * @property {number} gem_level
 * @property {string} icon_light
 * @property {string} icon_dark
 */

/**
 * @typedef {object} EditAvatarPayload
 * @property {string} _id
 * @property {string} avatar_id
 * @property {string} avatar_category
 * @property {bool} for_free
 * @property {bool} in_shop
 * @property {number} coins
 * @property {number} gem_level
 * @property {string} icon_light
 * @property {string} icon_dark
 */

/**
 * @typedef {object} UpdateAvatarIconsPayload
 * @property {string} avatar_id
 * @property {string} icon_light
 * @property {string} icon_dark
 */
function useAvatars() {
	const [data, setData] = useState({
		avatars: [],
		totalCount: 0
	})
	const [isLoading, setLoading] = useState(true)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchAvatars = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin_avatar?${qs.stringify(queryParams, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: { data: avatars, total_count } }) => {
						setData({ avatars, totalCount: total_count })
						resolve(avatars)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchAvatar = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`avatar id is not provided`))
				} else {
					setLoading(true)
					axios
						.get(`/admin_avatar/${id}`)
						.then(({ data: avatarData }) => {
							resolve(avatarData)
						})
						.catch((err) => {
							reject(err)
						})
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const updateAvatarIcons = useCallback(
		/**
		 * @param {UpdateAvatarIconsPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { avatar_id } = payload
				message.loading({
					content: 'updating avatar icons...',
					key: avatar_id
				})
				axios
					.post('/admin_avatar/icon_ids', payload)
					.then(({ data: avatarNewIcons }) => {
						message.success({
							content: 'avatar icons updated',
							key: avatar_id
						})
						resolve(avatarNewIcons)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update avatar icons',
							key: avatar_id
						})
						reject(err)
					})
			})
		},
		[]
	)

	const addNewAvatar = useCallback(
		/**
		 * @param {AddAvatarPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon_dark, icon_light, ...restPayload } = payload
				message.loading({
					content: 'adding new avatar...',
					key: payload.avatar_id
				})
				axios
					.post('/admin_avatar', restPayload)
					.then(({ data: newAvatar }) => {
						message.success({
							content: 'new avatar added',
							key: payload.avatar_id
						})
						enableUpdateStatistics()
						const { _id: avatar_id } = newAvatar

						updateAvatarIcons({ avatar_id, icon_light, icon_dark })
							.then((avatarIconsData) => {
								Object.assign(newAvatar, avatarIconsData)
							})
							.finally(() => {
								fetchAvatars(qs.parse(search))
								resolve(newAvatar)
							})
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new avatar',
							key: payload.avatar_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAvatars, search, updateAvatarIcons]
	)

	const deleteAvatar = useCallback(
		/**
		 * @param {string} id - avatar _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`avatar id is not provided`))
				} else {
					message.loading({ content: 'deleting avatar...', key: id })
					axios
						.delete(`/admin_avatar/${id}`)
						.then(() => {
							message.success({
								content: 'avatar deleted',
								key: id
							})
							enableUpdateStatistics()
							fetchAvatars(qs.parse(search))
							resolve()
						})
						.catch((err) => {
							message.error({
								content: 'deleting avatar operation failed',
								key: id
							})
							reject(err)
						})
				}
			})
		},
		[enableUpdateStatistics, fetchAvatars, search]
	)

	const editAvatar = useCallback(
		/**
		 * @param {EditAvatarPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { icon_dark, icon_light, ...restPayload } = payload || {}
				const { _id: avatar_id } = restPayload
				message.loading({
					content: 'updating avatar data...',
					key: avatar_id
				})

				axios
					.put('/admin_avatar', restPayload)
					.then(({ data: editedAvatar }) => {
						message.success({ content: 'avatar edited', key: avatar_id })
						enableUpdateStatistics()
						if (icon_light || icon_dark) {
							const iconsPayload = { avatar_id }
							if (icon_light) Object.assign(iconsPayload, { icon_light })
							if (icon_dark) Object.assign(iconsPayload, { icon_dark })

							updateAvatarIcons(iconsPayload)
								.then((updatedAvatarIcons) => {
									Object.assign(editedAvatar, updatedAvatarIcons)
								})
								.finally(() => {
									fetchAvatars(qs.parse(search))
									resolve(editedAvatar)
								})
						} else {
							fetchAvatars(qs.parse(search))
							resolve(editedAvatar)
						}
					})
					.catch((err) => {
						message.error({
							content: 'failed to update avatar',
							key: avatar_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAvatars, search, updateAvatarIcons]
	)

	return {
		data: data.avatars,
		totalCount: data.totalCount,
		isLoading,
		fetchAvatars,
		fetchAvatar,
		addNewAvatar,
		deleteAvatar,
		editAvatar
	}
}
export default useAvatars
